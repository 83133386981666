import React from "react"
import { Element } from "react-scroll"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Footer from "../components/footer"
import SectionPane from "../components/page/SectionPane"
import ProductWalkthrough from "../components/pages/home/ProductWalkthrough"
import TransformdStaticPlatform from "../components/staticcontent/TransformdStaticPlatform"
import TransformdStaticContent from "../components/staticcontent/TransformdStaticContent"
import TimeTransformd from "../components/contact/Transformd"
import globalEmitter from "../components/vendor/GlobalEmitter"
// @todo data source
const productDemoSlides = [
  {
    key: "personalised",
    headline: "Personalise your customer experience",
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/1-%5Bd%5D-personalised-cx-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/1-%5Bm%5D-personalised-cx-%5Bteaser%5D.png",
    body: (
      <>
        Your customers are more than an application number. Show them that you
        know them by pre-populating data and enabling personalisation throughout
        the digital engagement.
      </>
    ),
    url: "/platform/personalised-cx",
    variant: "primary",
  },
  {
    key: "automation",
    headline: "Work smarter with automations",
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bd%5D-integrations-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bm%5D-integrations-%5Bteaser%5D.png",
    body: (
      <>
        Transformd connects platforms, people and systems in real-time. Workflow
        and customers communications are streamlined through event triggered
        automations.
      </>
    ),
    url: "/platform/automation",
    variant: "red",
  },
  {
    key: "integrations",
    headline: (
      <>
        {" "}
        Integrate with powerful <br /> &amp; popular tools
      </>
    ),
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bd%5D-integrations-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bm%5D-integrations-%5Bteaser%5D.png",
    body: (
      <>
        Connect and integrate with major platforms for more control over your
        experience. With the ability to talk to most major platforms –
        Transformd can access relevant data for a frictionless form experience.
      </>
    ),
    url: "/platform/integrations",
    variant: "orange",
  },
  {
    key: "experiences-anyone-create",
    headline: "Build complex forms, simply",
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/2-%5Bd%5D-anyone-can-create-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/2-%5Bm%5D-anyone-can-create-%5Bteaser%5D.png",
    body: (
      <>
        Free up your development team and take control of your user experience
        with the Transformd builder. Simply drag, drop and configure to create
        and optimise digital forms.
      </>
    ),
    url: "/platform/anyone-can-create",
    variant: "red",
  },
  {
    key: "data",
    headline: <>Get to know your customer data</>,
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/5-%5Bd%5D-data-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/5-%5Bm%5D-data-%5Bteaser%5D.png",
    body: (
      <>
        There’s power in numbers. Analyse your customer data to better
        understand how your experience is performing. The more you know, the
        more you can optimise, improve and repeat.
      </>
    ),
    url: "/platform/data-analytics",
    variant: "dark",
  },
  {
    key: "beautiful-brands",
    headline: "Create cohesive, branded experiences",
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/4-%5Bd%5D-brands-%5Bteaser%5D.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/4-%5Bm%5D-brands-%5Bteaser%5D.png",
    body: (
      <>
        Customise and style your experiences to match your brand. Ensure that no
        matter where your customer comes from, they recognise where they are.
      </>
    ),
    url: "/platform/beautiful",
    variant: "green",
  },
  {
    key: "secure-private",
    headline: "Be secure & protected ",
    img:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/secure-web-image.png",
    mobileImg:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/secure-mobile-image.png",
    body: (
      <>
        Privacy and security are our number one priority. Know your data is
        securely protected whilst maintaining full ownership and control over
        how you use it.
      </>
    ),
    url: "/platform/security",
    variant: "light",
  },
]
const IndexPage = () => {
  React.useEffect(() => {
    window.getTransformdEmitter = globalEmitter
  }, [])
  return (
    <MainLayout hideFooter>
      <div className="container-fluid hero-container dot-back-color">
        <SectionPane
          size="xlg"
          variant="primary"
          className="pokerdots dot-back-color"
        >
          <TransformdStaticContent />
        </SectionPane>
      </div>
      <Element name="transformdStaticPlatformElement" />
      <SectionPane size="xxlg" variant="white">
        <TransformdStaticPlatform />
      </SectionPane>
      <SEO title="Personalised Customer Engagement Platform" />

      <ProductWalkthrough slides={productDemoSlides} />

      <div>
        <SectionPane size="xlg" variant="primary">
          <TimeTransformd globalEmitter={globalEmitter} />
        </SectionPane>
        <Footer />
      </div>
    </MainLayout>
  )
}

export default IndexPage
