import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const AnyoneCanCreatePage = () => (
  <MainLayout>
    <SEO title="Experiences anyone can create" />
    <Title
      fixedHeight={false}
      headline={<>Drag, Drop &amp; Configure</>}
      subTitle="Platform"
      variant="red"
    />

    <SectionPane className="pt-0" variant="red">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/2-%5Bd%5D-anyone-can-create.png"
          alt="Experiences anyone can create"
          className="d-none d-md-inline-block"
        />

        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/2-%5Bm%5D-anyone-can-create.png"
          alt="Experiences anyone can create"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container className="pt-md-5 pt-3">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
              Free up your development team and take control of your user
              experience with the Transformd builder. Simply drag, drop and
              configure to create and optimise digital forms.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <h4 className="mb-0 text-center text-md-left">Simple to build</h4>
            <p className="text-center text-md-left">
              Take ownership of your forms and launch optimised experiences
              without the need for code. Simply drag and drop fields, pages,
              sections and integration assets to create the foundations of your
              form. Then configure and customise to meet your business needs.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Powerful drag and drop functionality:
            </h4>
            <p className="text-center text-md-left">
              Makes building experiences simple and fast. With hundreds of
              predefined fields, pages, sections and integration assets,
              experiences are easy to build and publish in no time at
              all…..without the need for software coding knowledge.
            </p>

            <p className="text-center text-md-left">
              Because of the intuitive configuration, ownership of customer
              experiences can be owned and managed by an organisations customer
              experts (sales/ Marketing/ Customer Service) unleashing
              unprecedented levels of digital transformation scale within
              organisations.
            </p>
          </Col>
          <Col md="5">
            <h4 className="mb-0 text-center text-md-left">
              Automations that digitise the customer journey end to end:
            </h4>
            <p className="text-center text-md-left">
              Supported by our actions based framework transformd makes it easy
              to set-up pre defined business logic and automated actions
              triggered by events to create highly curated experiences and
              seamless customer interactions throughout their journey.
            </p>
            <h4 className="mb-0 text-center text-md-left">
              Clever business logic
            </h4>
            <p className="text-center text-md-left">
              Transformd’s automations framework makes it easy to set-up
              predefined business logic and automate workflows. Trigger events,
              manage customer interactions and create highly curated experiences
              that do everything for you.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <ProductBenefits id="drag-drop" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(AnyoneCanCreatePage)
