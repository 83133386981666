import React from "react"
import { Container } from "react-bootstrap"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"

const PlatformPage = () => (
  <MainLayout>
    <SEO title="Platform" />
    <Title headline="Our Platform" subTitle="Platform" />

    <Container>
      <p>This is placeholder for platform page.</p>
    </Container>
  </MainLayout>
)

export default PlatformPage
