import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const BeautifulPage = () => (
  <MainLayout>
    <SEO title="Embeddable brand friendly forms" />
    <Title
      fixedHeight={false}
      headline={
        <>Brand friendly</>
      }
      subTitle="Platform"
      variant="green"
    />

    <SectionPane className="pt-0" variant="green">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/4-%5Bd%5D-brands.png"
          alt="Beautiful brands, simple to embed"
          className="d-none d-md-inline-block"
        />
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/4-%5Bm%5D-brands.png"
          alt="Beautiful brands, simple to embed"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container className="pt-3 pt-md-5">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
              Customise and style your experiences to match your brand. Ensure that no matter where your customer comes from, 
              they recognise where they are.            
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>

            <h4 className="mb-0 text-center text-md-left">
              Branded forms
            </h4>
            <p className="text-center text-md-left">
              Our powerful brand rendering engine reproduces your unique brand styling for a consistent customer experience. 
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Contextual Personalisation
            </h4>
            <p className="text-center text-md-left">
              Our forms intelligently pick up individual profile cues, creating a tailored experience for your customers. 
            </p>

          </Col>
          <Col md="5">

            <h4 className="mb-0 text-center text-md-left">
              Easy to embed
            </h4>
            <p className="text-center text-md-left">
              Flexible technology allows you to embed these experiences into your existing digital platforms. 
              Whether it is your company website, online assets or staff portals – we make it easier to extend your brand footprint. 
            </p>

          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <ProductBenefits id="easy-share" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(BeautifulPage)
