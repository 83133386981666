import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import SectionPane from "../components/page/SectionPane"
import Title from "../components/page/Title"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="Solutions" />
    <Title
      headline="Page not found"
      subTitle="Uh oh! Something has gone wrong."
    />

    <SectionPane>
      <Container>
        <p className="text-center">
          Unfortunately the selected page could not be found. Please check the
          URL and try again.
        </p>

        <p className="text-center">
          Believe this is a mistake? Please{" "}
          <Link to="/contact">let us know</Link>.
        </p>
      </Container>
    </SectionPane>
  </MainLayout>
)

export default NotFoundPage
