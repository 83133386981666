import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import isEmpty from "lodash/isEmpty"

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import SectionPane from "../components/page/SectionPane"

const ArticleTemplate = ({
  data: {
    allNodeArticle: {
      nodes: [articleData],
    },
  },
  location,
}) => {
  const {
    body: { processed, summary },
    field_image: { alt: coverAlt },
    relationships: {
      field_tags: fieldTags,
      field_image: {
        uri: { url },
      },
    },
    title,
    revision_timestamp,
  } = articleData

  const [daysEscaped, setDaysEscaped] = useState(0)
  const [formatted, setFormatted] = useState("")
  const currentUrl = location.href
  const articleImage = `https://transformd.fishvision.net.au/${url}`

  useEffect(() => {
    const dateStr = new Date(revision_timestamp)
    const formattedDate = [
      dateStr.getDate(),
      dateStr.getMonth(),
      dateStr.getFullYear(),
    ]
    setDaysEscaped(new Date(new Date() - dateStr).getDate())
    setFormatted(formattedDate.join("/"))
  }, [])

  return (
    <MainLayout className="page-article">
      <SEO
        title={title}
        image={articleImage}
        description={summary}
        image={articleImage}
      />
      <Title
        fixedHeight={false}
        headline={"Smarter, faster, easier digitisation."}
      />
      <h1 className="page-article__title">{title}</h1>
      <div className="page-article__layout container">
        <div className="row">
          <div className="article-content">
            <div className="article-cover">
              <img src={articleImage} alt={coverAlt} />
            </div>
            <div
              className="article-content__summary"
              dangerouslySetInnerHTML={{ __html: summary }}
            />
            <div
              className="article-content__data"
              dangerouslySetInnerHTML={{ __html: processed }}
            />
          </div>
          <div className="article-meta-data">
            <h4>{formatted}</h4>
            <ul className="blog-article__tags">
              <li>Tags</li>
              {!isEmpty(fieldTags) &&
                fieldTags.map(({ name }, i) => (
                  <li key={i}>
                    <span>{name}</span>
                  </li>
                ))}
            </ul>
            <div className="page-article__social-share">
              <FacebookShareButton url={currentUrl}>
                <FacebookIcon size={48} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton url={currentUrl}>
                <LinkedinIcon size={48} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton url={currentUrl}>
                <TwitterIcon size={48} round={true} />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
      <SectionPane>
        <Container />
      </SectionPane>
    </MainLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allNodeArticle(filter: { field_slug: { eq: $slug } }) {
      totalCount
      nodes {
        revision_timestamp
        title
        field_slug
        body {
          format
          processed
          summary
        }
        field_image {
          width
          height
          alt
        }
        relationships {
          field_tags {
            name
          }
          field_image {
            uri {
              url
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
