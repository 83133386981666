import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"

const TMBankCaseStudy = () => (
  <MainLayout className="header--dark">
    <SEO title="Case Studies" />
    <Title
      headline={
        <>
          Teachers Mutual Bank
          <br />
          <span className="font-weight-normal">—</span>
        </>
      }
      subTitle="Case Study"
      variant="white"
      size="sm"
    />

    <SectionPane className="pt-5 mt-md-5 pb-5">
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 5 }}>
            <p className="sub-title">
              <span className="text-primary">
                Teachers Mutual Bank is one of Australia’s largest mutual banks,
              </span>{" "}
              with over 198,000 members and more than $6.9 billion in assets.
            </p>
            <p className="sub-title">
              Since 1966 the bank has helped secure the futures of Australian
              educators with its fi nancial services, products and member
              support – inspired by its values of advocacy, passion, and
              sustainability.
            </p>
            <h4 className="mb-0">The challenge</h4>
            <p>
              Like many organisations, the Bank has encountered significant
              change and opportunities as the company evolves offerings for the
              digital age.
            </p>

            <p>
              Driven by a vision of becoming Australia’s largest, most
              successful, and respected mutual bank serving the education sector
              and other essential services professions, two new brands (UniBank
              and Firefighters Mutual Bank) were added in recent years
              increasing their membership base.
            </p>

            <p>
              Bringing together the three individual mutual banks under the
              Teachers Mutual Bank Umbrella required the organisation to deliver
              a consistent and seamless user experience to its growing member
              base.
            </p>
          </Col>
          <Col md="5">
            <p>
              Simultaneously, consumer service expectations have evolved to a
              point where financial service providers must be able to empower
              clients whenever they want across devices, platforms, and
              locations.
            </p>

            <p>
              The Bank turned to Transformd to overcome some of these business
              challenges, while growing market share and improving operational
              efficiencies.
            </p>

            <p>
              Together the organisations embarked upon a tailored project
              designed to streamline and unify applications for the Bank’s
              products and services, and provide a secure and highly
              personalised online platform for members to easily and safely
              submit sensitive information.
            </p>

            <p>Engagement experiences included;</p>
            <ul className="mb-0">
              <li>O Week membership drive </li>
              <li>Credit Card applications</li>
              <li>Personal loans</li>
              <li>Member acquisition</li>
              <li>Home loan applications</li>
              <li>General web enquiries</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <Row className="mx-0">
      <Col
        md="6"
        className="justify-content-right d-md-flex d-inline-block mb-5 mb-md-0 px-0 align-items-center"
      >
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/web/case-studies/tmb-casestudy.png"
          alt="TMBank - Case Study"
          className="ml-auto"
        />
      </Col>
      <Col md="6">
        <div className="quote-block ml-md-5 pl-md-5 pt-md-5 mt-md-2">
          <p className="h4 md-h3 font-italic font-weight-normal text-base-dark-50w">
            “Transformd has given the Bank the ability to innovate and offer a
            better online experience at the speed out members need.
          </p>
          <p className="h4 md-h3 font-italic font-weight-normal text-base-dark-50w">
            The ease at which employees, existing members and new applicants can
            engage with the platform drives multiple efficiencies, and we’re
            constantly reviewing where else we can leverage its capabilities
            across the bank.”
          </p>
        </div>
      </Col>
    </Row>

    <SectionPane className="pt-3 pt-md-5 mt-md-5 pb-4 pb-md-5">
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 5 }}>
            <h4 className="mb-0">The solution</h4>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 1, span: 5 }}>
            <p className="text-base-dark-50w">
              Having taken the time to understand the Bank’s unique business
              challenges and needs, Transformd worked with the bank to deploy
              the Transformd Customer Engagement Platform.
            </p>
          </Col>
          <Col md="5">
            <p>
              The technology consolidated multiple customer management databases
              into the single Transformd Customer Engagement Platform,{" "}
              <span className="text-primary">
                equipping the Bank with enhanced control and agility of its
                performance.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <div className="d-md-flex justify-content-center">
      <img
        src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/web/case-studies/tmb-mobile.png"
        alt="TMB - Case study"
      />
    </div>

    <SectionPane className="pt-5 pb-4 pb-md-5 mt-md-4">
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 5 }}>
            <p>Powered by this new capability, the Bank was able to:</p>
          </Col>
        </Row>
        <Row className="mt-md-4">
          <Col md={{ offset: 1, span: 5 }}>
            <h4 className="mb-0">
              Rapidly personalise and publish personalised communications and
              experiences
            </h4>
            <p>
              Using prebuilt configurations in the Transformd Customer
              Engagement Platform, the Bank was able to easily and rapidly
              create personalised communications for members, which are shared
              across mobile, laptop and PCs.
            </p>
          </Col>
          <Col md="5">
            {" "}
            <h4 className="mb-0">Improve the speed and quality of service</h4>
            <p>
              Insights driven out of the Transformd Data Analytics Platform have
              enabled the business to test and learn and optimise the customer
              experiences. It also equipped internal stakeholders with a
              holistic and integrated view of all data which helped accelerate
              the speed at which member engagements could be resolved.
            </p>
          </Col>
        </Row>

        <Row className="mt-md-5">
          <Col md={{ offset: 1, span: 5 }}>
            <h4 className="mb-0">Automate responses and interactions</h4>
            <p>
              Event driven workflow features in Transformd Customer Engagement
              Platform automatically trigger prebuilt configurations when
              certain engagements occur, or information is provided. This
              ensured members and applicants were served personalised and
              relevant information that helped progress their query without
              being held up by resubmitting duplicate information.
            </p>

            <p>
              Consolidating multiple customer engagement platforms into a single
              location also empowers Transformd to validate sensitive data and
              pre-populate member information in real-time based on previous
              interactions. This further increased the personalised nature of
              the engagement, and the speed at which it was processed.
            </p>
          </Col>
          <Col md="5" className="d-none d-md-inline-block">
            <img
              src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/web/case-studies/tmb-photo.png"
              alt="TMB"
              className="mw-md-none"
            />
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <div className="justify-content-center d-block d-md-none mb-5">
      <img
        src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/web/case-studies/tmb-photo.png"
        alt="TMB"
      />
    </div>

    <SectionPane className="pt-md-5 pt-0 pb-5">
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 5 }}>
            <h4 className="mb-0">The result</h4>
            <p>
              The business benefits enabled by Transformd are best demonstrated
              by the positive impact made on one of the bank’s busiest periods –
              O Week.
            </p>
            <p>
              Armed with just a mobile device, the Bank was able to undertake
              and upload new applications on the spot – regardless of the
              service chosen. As a result of the simplified and convenient
              experience, the Bank is able to convert interested applicants into
              new members – helping the bank realise its vision of becoming
              Australia’s largest mutual bank.
            </p>

            <p>
              The consolidation of multiple data sets into a central database
              also boosted the user experience. The Bank could automatically
              sort through and distribute applications for approval, and new
              submissions were pre-populated from existing data sets to
              accelerate the process.
            </p>

            <ul>
              <li className="mb-4">
                The Transformd application form was accessible from any device,
                and easy for staff to use at events.
              </li>
              <li className="mb-4">
                The O Week 2018 campaign saw an 84% increase in application
                submissions.
              </li>
              <li className="mb-4">
                This increase resulted in an uplift of 91% to converted members
                compared with the previous year.
              </li>
              <li>
                Overall, conversion rates improved by 4% using the new solution.
              </li>
            </ul>
          </Col>
          <Col md={{span: 4, offset: 1}} className="mt-3 mt-md-4 mt-md-0">
            <div>
              <p className="h4 md-h3 font-italic font-weight-normal text-base-dark-50w">
                “Transformd has been a fundamental partner in our evolution.
              </p>

              <p className="h4 md-h3 font-italic font-weight-normal text-base-dark-50w">
                The customer engagement platform equipped us with the
                capabilities we need to deliver a seamless and consistent
                experience across our expanding product portfolio, and empowers
                us to rapidly satisfy the evolving and increasing demands of our
                growing member base.
              </p>

              <p className="h4 md-h3 font-italic font-weight-normal text-base-dark-50w">
                The future of the Bank is digital, with plans underway to use
                new digital technologies and drive automation. We’re partnering
                with Transformd to create this future, and are excited to draw
                upon the organisation’s enterprise as we continue to evolve and
                personalise the experiences we deliver.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionPane>
  </MainLayout>
)

export default TMBankCaseStudy
