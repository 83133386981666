import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import ProductWalkthroughContext from "./context"

const Controls = ({ currentSlide, slides }) => (
  <ProductWalkthroughContext.Consumer>
    {({ setActiveSlide }) => (
      <div className="container position-absolute h-100 m-auto product-walkthrough-controls-container d-none d-md-flex align-items-center">
        <ul className="product-walkthrough-controls list-unstyled ml-auto">
          {slides.map(({ key }) => (
            <li
              key={key}
              className={classNames("product-walkthrough-control", {
                "product-walkthrough-control--active": key === currentSlide,
              })}
              onClick={() => {
                if (key !== currentSlide) {
                  setActiveSlide(key)
                }
              }}
            />
          ))}
        </ul>
      </div>
    )}
  </ProductWalkthroughContext.Consumer>
)

Controls.propTypes = {
  currentSlide: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      variant: PropTypes.string,
    })
  ),
}

export default Controls
