import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const FooterMenu = ({ title, url, items }) => (
  <div className="footer-menu">
    {title && (
      <h5 className="sub-title-sm footer-menu-title">
        {url ? (
          <Link to={url} className="footer-menu-title-link">
            {title}
          </Link>
        ) : (
          <span className="text-white ls-1">{title}</span>
        )}
      </h5>
    )}
    {items.length > 0 && (
      <ul className="list-unstyled footer-menu-links">
        {items.map(({ key, text, url }) => (
          <li key={key}>
            <Link to={url}>{text}</Link>
          </li>
        ))}
      </ul>
    )}
  </div>
)

FooterMenu.defaultProps = {
  items: [],
  title: "",
}

FooterMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  url: PropTypes.string,
}

export default FooterMenu
