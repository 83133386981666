import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const DataAnalyticsPage = () => (
  <MainLayout>
    <SEO title="Data analytics &amp; optimisation" />
    <Title
      fixedHeight={false}
      headline={
        <>
          Data analytics
          <br />
          &amp; optimisation
        </>
      }
      subTitle="Platform"
      variant="dark2"
    />

    <SectionPane className="pt-0" variant="dark2">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/5-%5Bd%5D-data.png"
          alt="Data analytics &amp; optimisation"
          className="d-none d-md-inline-block"
        />
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/5-%5Bm%5D-data.png"
          alt="Data analytics &amp; optimisation"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container className="pt-md-5 pt-3">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
              There’s power in numbers. Analyse your customer data to better understand how your experience is performing. 
              The more you know, the more you can optimise, improve and repeat.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <h4>Measure and track completion rates</h4>
            <p className="text-center text-md-left">
              Your customers are dropping off during signup… why? With Transformd, you can instantly see how to optimise their experience. 
              Gain valuable insights into your customer journey and minimise the time it takes to complete a form for better conversions. 
            </p>

            <p className="text-center text-md-left">
              Measure completion rates, understand why users do not complete the
              process. Minimising the time required to complete the process can
              be crucial in today’s world of short attention spans.
            </p>

            <p className="text-center text-md-left">
              <strong>We can help you answer questions such as:</strong>
            </p>

            <ul>
              <li>Are my questions clearly worded?</li>
              <li>Is the flow of my solution intuitive?</li>
              <li>Is a validation error frustrating my users?</li>
              <li>How much time does it take to complete?</li>
            </ul>
          </Col>
          <Col md="5">
            <h4>What do you need to know?</h4>
            <p className="text-center text-md-left">
              Identify validation errors, track time to completion and press ‘pause’ on the exact moments where customers are leaving so 
              you can figure out the reason. Transformd lets you track user behaviour, right down to the keystroke. 
            </p>

            <p className="text-center text-md-left">
              Transformd automatically gathers data that will help you
              understand how users are engaging with all aspects of your
              solution. Track completions, errors, time spent, keystrokes, and
              exactly pinpoint the field the user interacted with prior to
              abandoning the process on a field and page level.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <ProductBenefits id="data-analytics" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(DataAnalyticsPage)
