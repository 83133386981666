import React from "react"
import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import { Container, Row, Col } from "react-bootstrap"

const PlatformPage = () => (
  <MainLayout>
    <SEO title="Terms of Business" />
    <Title headline="Transformd Terms of Business" subTitle="July 2021" />

    <Container className="px-4">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <p>
            <strong>MSA - Terms of Business</strong>
          </p>

          <p>
            Thank you for your interest in our{" "}
            <em>Transformd software as a service offering </em>and related&nbsp;
            services. This document sets out the terms on which we offer to
            provide our Products and&nbsp; Services to our clients.&nbsp; &nbsp;
          </p>

          <p>
            (In this document, ‘we’ or ‘us’ means Transformd Pty Ltd (ABN 52 602
            839 707) and ‘you’ or ‘Client’&nbsp; means the company, Government
            agency or other legal entity named on an Order Form.) &nbsp;
          </p>

          <p>
            <strong>1. </strong>
            <strong>This creates a binding contract &nbsp;</strong>
          </p>

          <p>1.1 Contract &nbsp;</p>

          <p>
            We offer to supply the Products and Services to you on these general
            terms, plus any other terms in&nbsp; an Order Form. You may accept
            this offer by: signing our Order Form; issuing a purchase order for
            the&nbsp; Products &amp;/or Services; clicking on ‘I accept’ (or
            similar term) in an online form; or starting to use the&nbsp;
            Products. &nbsp;
          </p>

          <p>
            If you do any of these things, a legally binding contract will be
            created, comprising these general&nbsp; terms plus the Order Form
            terms (Contract). &nbsp;
          </p>

          <p>1.2 Order Forms Prevail &nbsp;</p>

          <p>
            If there is any inconsistency between these general terms and
            specific terms agreed in writing in an&nbsp; Order Form or purchase
            order, the specific terms prevail. &nbsp;
          </p>

          <p>1.3 Entire Contract&nbsp;</p>

          <p>
            This Contract contains everything we have agreed relating to the
            matters it deals with. You&nbsp; acknowledge that you have not
            relied on any warranty or representation which is not
            specifically&nbsp; stated in the Order Form. If you give us your
            terms and conditions (e.g. as referred to in a purchase&nbsp; order
            or other document), those terms and conditions are of no legal
            effect relating to our Products&nbsp; and Services and do not form
            part of this Contract.
          </p>

          <p>
            <strong>2. </strong>
            <strong>Definitions &nbsp;</strong>
          </p>

          <p>
            <strong>Additional Services </strong>means Services which we may
            provide in addition to the Support Services, such as&nbsp;
            diagnosing and correcting reported problems which are outside the
            scope of Support Services (e.g.&nbsp; problems caused by operator
            error, hardware failure or failure to maintain current back-ups of
            data&nbsp; and software), assistance with installing, implementing
            or configuring the Product, developing&nbsp; software
            customisations, providing training or consulting. &nbsp;
          </p>

          <p>
            <strong>Approved Use </strong>means the use for which the Client may
            use the Product as set out in an Order Form,&nbsp; such as an
            approved number of users, forms, or instances of the Products, or
            Client sites or territories,&nbsp; or lines of business, or other
            restrictions on use. &nbsp;
          </p>

          <p>
            <strong>Charges </strong>means the Charges for the Products and
            Services as set out in an Order Form (as varied&nbsp; under these
            terms). &nbsp;
          </p>

          <p>
            <strong>Confidential Information </strong>means any information
            provided by a party or any of its employees, agents,&nbsp; related
            parties or representatives to the other party or any of its
            employees, agents, related parties or&nbsp; representatives, or
            otherwise obtained by that party (whether oral, written or viewed by
            inspection)&nbsp; which is marked as "proprietary" or "confidential"
            or similar language or which the recipient knows or&nbsp; reasonably
            should know is proprietary or confidential. &nbsp;
          </p>

          <p>
            <strong>Confidential Information </strong>includes: &nbsp;
          </p>

          <p>a. the terms of this Contract and any Order Form; &nbsp;</p>

          <p>
            b. Intellectual Property, software, specifications, ideas, concepts,
            plans, formulas, drawings,&nbsp; procedures, financial information,
            reports, technical information and forecasts;&nbsp; c. the
            Transformd Materials, and; &nbsp;
          </p>

          <p>
            d. details of each party’s internal systems, business processes,
            future product plans, marketing,&nbsp; customers, prospects,
            finances, personnel and operations. &nbsp;
          </p>

          <p>
            <strong>Client Data </strong>means the proprietary data and content
            of the client provided to Transformed for the&nbsp; purpose of
            providing or testing the Services.&nbsp;
          </p>

          <p>
            <strong>Documentation </strong>means documents about the Products or
            Services as provided by us from time to time,&nbsp; and may include
            installation guidelines, specification documents and user guides and
            other resources&nbsp; provided on a web portal. &nbsp;
          </p>

          <p>
            <strong>End User </strong>means each person who has access to, uses
            or operates the Products purchased or licensed&nbsp; by Client,
            including Client’s employees, invitees, agents and subcontractors
            (but not including our&nbsp; employees, agents and subcontractors).
            &nbsp;
          </p>

          <p>
            <strong>Infringement Claim </strong>means any claim made against
            Client alleging that any part of the Transformd&nbsp; Materials or
            Client’s authorised use of the Transformd Materials infringes any
            third party's Intellectual&nbsp; Property Rights. &nbsp;
          </p>

          <p>
            <strong>Initial Term </strong>means the initial term as set out in
            an Order Form. &nbsp;
          </p>

          <p>
            <strong>Intellectual Property </strong>or{" "}
            <strong>Intellectual Property Rights </strong>includes copyright,
            trade mark, design, patents&nbsp; (whether registered or not),
            trade, business or company names, domain names, or other
            proprietary&nbsp; rights, Confidential Information or any rights to
            registration of such rights existing in any country, whether created
            before, on or after the date of the Order Form. &nbsp;
          </p>

          <p>
            <strong>Transformd Materials </strong>means materials created by us
            and provided to you in the course of performing&nbsp; this Contract
            including the Documentation, Software designs and drawings, and
            reports provided as&nbsp; part of the Support Services. &nbsp;
          </p>

          <p>
            <strong>Modern Slavery Laws </strong>means the Modern Slavery Act
            2018 (Cth) and any state laws that relate to&nbsp; modern slavery.
            &nbsp;
          </p>

          <p>
            <strong>License </strong>means your right to use the Transformd
            Materials. &nbsp;
          </p>

          <p>
            <strong>Order Form </strong>means an order form for Products
            &amp;/or Services signed by you and us.&nbsp;
          </p>

          <p>
            <strong>Products&nbsp; </strong>means the Transformd Products set
            out in an Order Form.&nbsp;
          </p>

          <p>
            <strong>Services </strong>means, collectively,&nbsp; Support
            Services and any Additional Services.
          </p>

          <p>
            <strong>Software </strong>means: &nbsp;
          </p>

          <p>a. the object code versions of the software Products; &nbsp;</p>

          <p>
            b. the object code versions of any other software we provide to you
            under this Contract; and &nbsp;
          </p>

          <p>
            c. any updates and additions to the software described in (a) and
            (b) that we make, including any&nbsp; software delivered through
            Support Services or Additional Services. &nbsp;
          </p>

          <p>
            <strong>Start Date </strong>means the date set out on an Order Form.
            &nbsp;
          </p>

          <p>
            Subscription License means a License that continues for so long as
            you pay the Charges for Support&nbsp; Services. &nbsp;
          </p>

          <p>
            <strong>Support Services </strong>means the services set out in
            Schedule 1, as updated by us from time to time, and&nbsp; any
            additional or different services described in an Order Form. &nbsp;
          </p>

          <p>
            <strong>Term </strong>means the Initial Term described in an Order
            Form plus any renewal term as set out in clause&nbsp; 9.1, until
            terminated under clause 9. &nbsp;
          </p>

          <p>
            <strong>Warranty Period </strong>means for so long as you pay the
            Subscription Charges and orSupport Services. &nbsp;
          </p>

          <p>
            <strong>3. </strong>
            <strong>Ordering Products and services &nbsp;</strong>
          </p>

          <p>3.1 Order Forms &nbsp;</p>

          <p>
            You may order Products and Services by both of us signing a written
            Order Form. We will prepare an&nbsp; Order Form, which will include
            details of the Products ordered (and licensing terms); the scope of
            any&nbsp; Services; and the Charges. &nbsp;
          </p>

          <p>3.2 Delivery, License expiry for non-payment &nbsp;</p>

          <p>
            Promptly after the Start Date (or other date agreed on an Order
            Form), we will deliver the Product to&nbsp; you. You acknowledge
            that we may prevent use of the Software if our Charges are not paid
            within&nbsp; agreed time limits. &nbsp;
          </p>

          <p>
            <strong>4. </strong>
            <strong>Grant of License &nbsp;</strong>
          </p>

          <p>
            4.1 License
            <br />
          </p>

          <p>
            Subject to all the other terms of this Contract, we grant to you a
            non-exclusive, non-transferable&nbsp; license to use the Transformd
            Materials during the Term. You acknowledge that: &nbsp;
          </p>

          <p>a. the Products are licensed, not sold, to you; &nbsp;</p>

          <p>
            b. we reserve all rights not expressly granted by this Contract
            including ownership of all&nbsp; Intellectual Property Rights in the
            Transformd Materials, and; &nbsp;
          </p>

          <p>
            c. no development to your specifications or based on your feedback
            or suggestions gives you&nbsp; ownership of any rights in any
            invention or derivative work. &nbsp;
          </p>

          <p>4.2 Duration &nbsp;</p>

          <p>
            The License begins when the Product is made available to you and
            continues as a Subscription License.&nbsp; The License continues for
            so long as you pay the Charges for Support Services. &nbsp;
          </p>

          <p>4.3 Limitations &nbsp;</p>

          <p>Means: &nbsp;</p>

          <p>
            a. You may only use the Products within the Approved Use/s and other
            limitations as set out in&nbsp; the relevant Order Form. &nbsp;
          </p>

          <p>
            b. Unless permitted in an Order Form, the Transformd Materials must
            only be used by you and&nbsp; for your benefit; and you must not use
            the Product in a production environment for the&nbsp; purposes of
            resale or to process the data or business of any third party or to
            operate a&nbsp; bureau service. &nbsp;
          </p>

          <p>
            c. You may not assign or transfer the Product or your License or
            sublicense the Software to&nbsp; any third party without our prior
            written consent. &nbsp;
          </p>

          <p>
            d. Your License to use the Transformd Materials is subject to clause
            6 &nbsp;
          </p>

          <p>
            (<em>Confidentiality</em>). &nbsp;
          </p>

          <p>e. You must not copy the Transformd Materials.</p>

          <p>f. You must not: &nbsp;</p>

          <p>
            i. make derivative works of the Products, or modify, alter or change
            the Products; &nbsp;
          </p>

          <p>
            ii. allow any person, other than your employees, agents or
            representatives, to access and&nbsp; use the Products or
            Documentation; and, before permitting any third party agent or&nbsp;
            representative to access and use the Products, you will require such
            third party agent&nbsp; or representative to agree in writing to
            only use the Products during the Term on your&nbsp; behalf in
            accordance with this Contract, and to maintain all Confidential
            Information of&nbsp; Transformd in strict confidence. You shall
            remain responsible for such third party’s use&nbsp; of the Products;
            &nbsp;
          </p>

          <p>
            iii. disassemble, decompile, reverse-engineer, or otherwise attempt
            to view or access the&nbsp; source code to the Products; &nbsp;
          </p>

          <p>
            iv. copy, reproduce or transmit to the public any of the Transformd
            Materials; &nbsp;
          </p>

          <p>
            v. attempt to unlock or bypass any initialisation system, encryption
            methods or copy&nbsp; protection devices in the Products; &nbsp;
          </p>

          <p>
            vi. alter, remove or obscure any patent, trademark or copyright
            notice in the Products; or
            <br />
            vii. use components of a Product independent of the Products they
            comprise.&nbsp;
          </p>

          <p>
            g. Any unauthorised use of the Products, or unauthorised
            modifications, alterations or changes to&nbsp; the Products, will
            terminate the warranties provided in this Contract and entitle us
            to&nbsp; terminate the License and Support Services for such
            Products. &nbsp;
          </p>

          <p>4.4 Use &nbsp;</p>

          <p>
            You have sole responsibility for your use of the Product, including
            using the Product in accordance with&nbsp; the Documentation or
            other instructions provided by us and ensuring your relevant staff
            are trained&nbsp; in using the Product. &nbsp;
          </p>

          <p>4.5 Export Controls &nbsp;</p>

          <p>
            You will comply with all export laws and restrictions and
            regulations of Australia. &nbsp;
          </p>

          <p>4.6 Third Party Software &nbsp;</p>

          <p>
            The Product may include third party software which may be subject to
            additional or different terms or&nbsp; conditions. To the extent
            that any third party software terms conflict with any of these
            terms, the third&nbsp; party software terms will prevail with
            respect to your use and distribution of the applicable third
            party&nbsp; software. &nbsp;
          </p>

          <p>
            We may incorporate free or open source material in the Product. We
            are responsible for ensuring that&nbsp; the License terms of any
            such free or open source material do not conflict with the License
            for the&nbsp; Product, or otherwise prevent you from using the
            Product in accordance with this Contract. &nbsp;
          </p>

          <p>4.7 Escrow &nbsp;</p>

          <p>
            If you ask us in writing, we will add you as a beneficiary to our
            Escrow Agreement with Iron Mountain&nbsp; (or any replacement escrow
            agent). You are responsible for paying all charges to the escrow
            agent as&nbsp; well as our charge of $1,000 per year. &nbsp;
          </p>

          <p>
            We will notify the escrow agent to release a copy of the source code
            of the Software to you if any of&nbsp; the following things happen:
            &nbsp;
          </p>

          <p>
            a. We become subject to any form of external insolvency
            administration, including having an&nbsp; administrator, receiver or
            liquidator appointed; &nbsp;
          </p>

          <p>
            b. We terminate Support Services for the Products (except because of
            your breach or failure to&nbsp; pay applicable support Charges) and
            we have not made available to you a superseding version&nbsp; of the
            Products; &nbsp;
          </p>

          <p>
            c. We stop conducting business in the ordinary course; or &nbsp;
          </p>

          <p>
            d. Any other release condition we agree to in our agreement with the
            escrow agent.&nbsp;
            <br />
            <br />
            If you get a copy of the source code to the Products under this
            clause, you may use the source code on&nbsp; the same terms as the
            License, and subject to all the terms of this Contract. Under no
            circumstances&nbsp; may you market, distribute or otherwise provide
            the Products or source code, or any derivative, to any&nbsp; third
            party. &nbsp;
          </p>

          <p>
            <strong>5. </strong>
            <strong>
              Support Services
              <br />
            </strong>
          </p>

          <p>5.1 Providing Support Services &nbsp;</p>

          <p>
            We will provide the Support Services to you as described in Schedule
            1 (which we may amend from&nbsp; time to time) and otherwise in
            accordance with this clause 5.1. Unless otherwise stated in the
            Order&nbsp; Form Support Services are restricted to supporting the
            underlying Transformd Platform and do not&nbsp; extend to solutions
            built on the Transformd platform, platform configurations or
            extensions built&nbsp; specifically for the Client.&nbsp;
            <br />
            5.2 Duration &nbsp;
          </p>

          <p>5.2.1 Unless agreed otherwise in an Order Form: &nbsp;</p>

          <p>For our Subscription License: &nbsp;</p>

          <p>
            a. We will provide Support Services for the Initial Term. You may
            not terminate Support Services&nbsp; early (unless you terminate
            this Contract for our insolvency or breach under clause 10.2,
            in&nbsp; which case the License also terminates); &nbsp;
          </p>

          <p>
            b. After the end of the Initial Term, the Support Services (and
            License) automatically renew each&nbsp; year unless you terminate
            Support Services by giving two months’ written notice before
            the&nbsp; renewal date. We will give you a reminder three months
            before the renewal date;&nbsp;
            <br />
            c. You will pay the Charges for Support Services (together with
            Charges for the License) yearly,&nbsp; quarterly or monthly in
            advance, as set out in an Order Form. &nbsp;
          </p>

          <p>5.3 Client Assistance &nbsp;</p>

          <p>
            In order for us to provide the Support Services, you will be
            responsible for the following items or&nbsp; activities, plus any
            responsibilities set out in an Order Form. You acknowledge that
            failure to provide&nbsp; such assistance will affect our ability to
            provide Support Services within target response or resolution&nbsp;
            times. &nbsp;
          </p>

          <p>
            a. You will ensure your personnel are trained in the operation of
            the Products, have knowledge&nbsp; and experience of software
            products sufficient for proper interaction with our technical
            staff&nbsp; regarding support services, including authority to
            implement remedial actions as instructed by&nbsp; us. &nbsp;
          </p>

          <p>
            b. You will give us sufficient documentation, data, details and
            assistance with respect to any&nbsp; reported errors so as to enable
            us to reproduce and verify the same as an error.&nbsp; c. Where the
            Product is Client Hosted, you will assist us to diagnose and correct
            reported errors&nbsp; by providing reasonable access (on-site or
            remote as requested by us) to the applicable&nbsp; Software, the
            computer environment and hardware on which such Software is
            installed and&nbsp; operating.&nbsp;
            <br />
          </p>

          <p>
            <strong>6. </strong>
            <strong>Charges &nbsp;</strong>
          </p>

          <p>6.1 Charges, Taxes and Payment Terms &nbsp;</p>

          <p>
            You agree to pay us the Charges, in the amounts, currency and
            instalments as set out in each Order&nbsp; Form. Where we are
            obliged to charge, pay or account for a value added tax, goods and
            services tax,&nbsp; sales tax or other similar consumption tax or we
            are subject to any customs, import, tariff or similar&nbsp; duty as
            part of providing the Products or Services, the invoiced price shall
            be increased by the amount&nbsp; of such tax or charge. You will pay
            all amounts within 14 days from the date of our invoice. Recurring
            fees are payable in advance from the beginning of the next billing
            period. &nbsp;
          </p>

          <p>6.2 Expenses &nbsp;</p>

          <p>
            Unless specified otherwise in an Order Form, you will reimburse us
            for all reasonable expenses we&nbsp; incur in providing the Support
            Services or Additional Services, including travel and
            accommodation&nbsp; costs. We will seek your consent before
            incurring any expense over $2,000 and will provide reasonable&nbsp;
            supporting details of all such expenses on our invoices. &nbsp;
          </p>

          <p>6.3 Overdue &nbsp;</p>

          <p>
            If any payments are overdue then we may, after giving you at least
            two weeks’ notice, suspend supply&nbsp; of Products or Services. As
            a condition of any subsequent supply, we may vary your payment
            terms,&nbsp; including requiring prepayment or security of payment.
            We may charge interest on late payments at&nbsp; the rate of one
            percent (1%) per month or the highest amount permitted by law,
            whichever is greater,&nbsp; calculated daily from the due date.
            &nbsp;
          </p>

          <p>
            <strong>7. </strong>
            <strong>Confidentiality &nbsp;</strong>
          </p>

          <p>7.1 Obligations &nbsp;</p>

          <p>
            Where one party (in this clause, Recipient) receives Confidential
            Information from the other party&nbsp; (Discloser), Recipient must:
            &nbsp;
          </p>

          <p>
            a. hold the Discloser’s Confidential Information in strict
            confidence, and apply at least the standard&nbsp; of care used by
            the Recipient in protecting its own Confidential Information, but
            not less than&nbsp; a reasonable standard of care; &nbsp;
          </p>

          <p>
            b. not disclose such Confidential Information to any third party,
            except as permitted under this&nbsp; Contract;&nbsp;
          </p>

          <p>
            c. not use any Confidential Information of the Disclosure except as
            reasonably required to exercise&nbsp; its rights or perform its
            obligations under this Contract; &nbsp;
          </p>

          <p>
            d. immediately notify Disclosure of any potential, suspected or
            actual unauthorised use, copying&nbsp; or disclosure of its
            Confidential Information; and &nbsp;
          </p>

          <p>
            e. cause its employees, subcontractors, agents and, if permitted,
            related companies, who require&nbsp; access to such Confidential
            Information to abide by such obligations. &nbsp;
          </p>

          <p>7.2 Permitted use &nbsp;</p>

          <p>
            Notwithstanding clause 7.1, Recipient may use or disclose the
            Confidential Information to the extent&nbsp; necessary to comply
            with any law or the requirements of a regulatory body (including a
            stock&nbsp; exchange), or to obtain professional legal or accounting
            advice, or for use in legal proceedings&nbsp; regarding this
            Contract. &nbsp;
          </p>

          <p>7.3 Exceptions &nbsp;</p>

          <p>
            Clause 7.1 does not apply to Confidential Information which
            Recipient can prove by written&nbsp; evidence: &nbsp;
          </p>

          <p>
            a. is in or becomes part of the public domain other than through
            breach of an obligation of&nbsp; confidence; &nbsp;
          </p>

          <p>
            b. was known to Recipient at the time of disclosure, unless such
            knowledge arose through
            <br />
            breach of an obligation of confidence; &nbsp;
          </p>

          <p>c. was independently developed by Recipient; or &nbsp;</p>

          <p>
            d. is acquired from a third party who was entitled to disclose it.
            &nbsp;
          </p>

          <p>7.4 Return or destroy &nbsp;</p>

          <p>
            Upon termination of this Contract, and at the written request of the
            Disclosure, the Recipient shall&nbsp; promptly return or destroy all
            Confidential Information in its possession and certify its
            destruction in&nbsp; writing, provided however, that the Recipient
            may retain one copy of the returned or destroyed items&nbsp; for
            archival purposes in accordance with its records-retention policies
            and subject to this clause. The&nbsp; obligations in this clause 7
            continue to apply after this Contract expires or is terminated.
            &nbsp;
          </p>

          <p>7.5 Right to Injunction &nbsp;</p>

          <p>
            The parties agree that the Disclosure may be irreparably damaged if
            the obligations under this clause 7&nbsp; are not enforced and such
            party may not have an adequate remedy in the event of a breach
            by&nbsp; Recipient; and therefore the Disclosure is entitled to
            seek, in addition to other available remedies, an&nbsp; injunction
            or any other appropriate equitable order or decree restraining any
            actual, threatened or&nbsp; further breaches of the Recipient’s
            obligations under this clause 7. &nbsp;
          </p>

          <p>7.6 Publicity &nbsp;</p>

          <p>
            We may prepare a press release, case study or other material
            regarding your use of the Products, with&nbsp; publication subject
            to your prior written consent, which will not be unreasonably
            withheld or delayed.&nbsp; Subject to your prior written consent, we
            may also include your name in any client list. Otherwise,&nbsp;
            neither party will use the name of the other party in publicity
            releases or similar activity without the&nbsp; consent of the other
            party. &nbsp;
          </p>

          <p>7.7 Personal Information &nbsp;</p>

          <p>
            If as a result of this Contract, we are able to access any
            information about identifiable individuals&nbsp; (“Personal
            Information” or “Sensitive Information”) held by or on behalf of you
            then we:
            <br />
            (a) must comply with the Privacy Act and all other applicable
            privacy laws and such other data&nbsp; protection laws as may be in
            force from time to time which regulate the collection, storage, use
            and&nbsp; disclosure of information, as if we were regulated by
            these laws; &nbsp;
          </p>

          <p>
            (b) must comply with any of your directions that are consistent with
            the laws, codes and policies&nbsp; referred to in clauses 7.7(a) and
            7.7(b) above; &nbsp;
          </p>

          <p>
            (c) must use the information only for the purposes of performing our
            obligations under this Contract;&nbsp;
            <br />
            (d) must restrict access to any information to our Representatives
            who need to access information&nbsp; to fulfil our obligations under
            this Contract; &nbsp;
          </p>

          <p>
            (e) must promptly notify you in writing of any request made by such
            an individual for access to the&nbsp; information; &nbsp;
          </p>

          <p>
            (f) must promptly notify you in writing in the event that we become
            aware of any privacy related&nbsp; complaint and must cooperate with
            you in the resolution of any complaint under, or relating to,
            any&nbsp; of the laws, codes or policies referred to in 7.7(b)
            above;&nbsp;
          </p>

          <p>
            (g) must immediately notify you in writing in the event that we
            become aware of any unauthorised&nbsp; access, unauthorised
            disclosure, interference (including hacking), misuse or loss of the
            information;&nbsp; and &nbsp;
          </p>

          <p>
            (h) must obtain prior written consent from you. If we intend to send
            or disclose information relating&nbsp; to this Contract outside of
            Australia; and must provide you with attestations with respect to
            privacy on a regular basis or as required by you. &nbsp;
          </p>

          <p>7.8 Data Security &nbsp;</p>

          <p>We acknowledge that: &nbsp;</p>

          <p>
            (a) your ability to maintain information security is of utmost
            importance and the security of Client&nbsp; Data is fundamental to
            your business; &nbsp;
          </p>

          <p>
            (b) you, as a provider of financial services, is entrusted with
            personal and sensitive information about&nbsp; its customers,
            including health and financial information which must be held
            securely; &nbsp;
          </p>

          <p>
            (c) you have obligations to report data breaches and/or security
            incidents to Australian&nbsp; Government regulators on a timely
            basis; and &nbsp;
          </p>

          <p>
            (d) we must have processes and controls in place to promptly
            identify and report data breaches and/or&nbsp; security incidents to
            you so as to assist you to meet your legal and regulatory
            obligations and&nbsp; community expectations. &nbsp;
          </p>

          <p>
            (e) establish, maintain, enforce and continuously improve safety and
            Information security procedures,&nbsp; controls and processes
            against the unauthorised use, access, destruction, loss or
            alteration of Client Data and your other Confidential Information;
            &nbsp;
          </p>

          <p>
            (f) establish, maintain, enforce and continuously improve safety and
            Information security procedures,&nbsp; controls and processes
            against the unauthorised use, access, destruction, loss or
            alteration of Client Data and your other Confidential Information;
            &nbsp;
          </p>

          <p>
            (g) not make any undocumented, unreported or unauthorised
            configuration changes to any&nbsp; information assets or to the
            information security procedures, controls and processes that secure
            Cient&nbsp; Data, if those changes would decrease the protections
            afforded to Client Data; &nbsp;
          </p>

          <p>
            (h) notify and keep you notified at all times of our current
            information security procedures, controls&nbsp; and processes and
            any amendments to such procedures, controls and processes which
            adversely&nbsp; affect, or are likely to adversely affect, your
            ability to manage the Services or the security of the&nbsp; Services
            or maintain information security. &nbsp;
          </p>

          <p>
            7.8A &nbsp; If we become aware of any security incident we must
            immediately (and in any event within 24&nbsp; hours): &nbsp;
          </p>

          <p>(a) notify you of the security incident; &nbsp;</p>

          <p>(b) identify the cause of the security incident ; &nbsp;</p>

          <p>
            (c) use best endeavours to rectify any actual security incident and
            its consequences; &nbsp;
          </p>

          <p>
            (d) use best endeavours to ensure that any potential security
            incident does not become an actual&nbsp; security incident; &nbsp;
          </p>

          <p>
            (e) investigate any alleged or suspected security incident; and
            either: &nbsp;
          </p>

          <p>
            (f) implement rectification measures if an actual security incident
            occurred or a security incident is&nbsp; likely to occur; or&nbsp;
          </p>

          <p>
            (g) confirm to you that no actual security incident occurred or is
            likely to occur; &nbsp;
          </p>

          <p>
            (h) provide you with a report detailing the cause of, and procedure
            for correcting, the security&nbsp; incident; &nbsp;
          </p>

          <p>
            (i) provide you with reasonable evidence that the security incident
            will not occur again; and &nbsp;
          </p>

          <p>
            (j) certify that we have taken all commercially reasonable steps to
            prevent any recurrence of such a&nbsp; security incident. &nbsp;
          </p>

          <p>
            <strong>8. </strong>
            <strong>Warranties &nbsp;</strong>
          </p>

          <p>8.1 General &nbsp;</p>

          <p>
            We represent and warrant that we have the necessary authority to
            grant the License and perform all&nbsp; our obligations under this
            Contract. &nbsp;
          </p>

          <p>8.2 Product Limited Warranty&nbsp;</p>

          <p>
            8.2.1 We represent and warrant that during the Warranty Period the
            Products shall perform all&nbsp; material functions set out in the
            applicable Documentation and shall otherwise operate in
            substantial&nbsp; accordance with such Documentation. &nbsp;
          </p>

          <p>
            8.2.2 If, during the Warranty Period the Products fail to comply
            with this warranty, you must notify us&nbsp; in writing of any
            alleged defects with the Products. We will have 30 days from receipt
            of such notice&nbsp; or an additional period of time as reasonably
            agreed to by the parties to correct such defects in&nbsp; accordance
            with Support Services in Schedule 1. &nbsp;
          </p>

          <p>
            8.2.3 If we are unable to correct such defects in a reasonable time,
            you may elect to terminate the&nbsp; License to such Product during
            the Warranty Period; and if so, we will give you, as your
            exclusive&nbsp; remedy, a refund of all Charges previously paid for
            such Products. &nbsp;
          </p>

          <p>8.3 Virus &nbsp;</p>

          <p>
            8.3.1 We represent and warrant that to the best of our knowledge
            after utilizing commercially&nbsp; available virus-checking
            software, the Products as delivered to you will be free from
            computer&nbsp; software viruses, worms, trap doors, back doors or
            other functions (collectively, Virus) that&nbsp; detrimentally
            interferes with or otherwise adversely affects your use of the
            Products. &nbsp;
          </p>

          <p>
            8.3.2 If you detect a Virus, you will promptly notify us and use
            your best commercial efforts to mitigate&nbsp; against any damages
            or effects caused by the Virus; while we will use our best
            commercial efforts to&nbsp; promptly eliminate such virus. &nbsp;
          </p>

          <p>8.4 Services &nbsp;</p>

          <p>8.4.1 We warrant that the Services will be provided: &nbsp;</p>

          <p>
            a. promptly, carefully, exercising all due care, skill and
            judgement, in an efficient and professional&nbsp; manner and in
            accordance with generally accepted professional and business
            practices; and&nbsp; b. using appropriately trained and skilled
            personnel. &nbsp;
          </p>

          <p>
            8.4.2 If the Services fail to comply with this warranty, you will
            promptly notify us in writing including&nbsp; reasonable details of
            any alleged defects. Upon such notice, we will, as your exclusive
            remedy, promptly re-perform any such Services in accordance with
            this Contract. We are not responsible for&nbsp; any delay or defect
            in performing any Services due to any cause outside our reasonable
            control. &nbsp;
          </p>

          <p>8.5 Disclaimer &nbsp;</p>

          <p>
            We do not warrant that the Products will operate error-free or that
            we will correct all Product errors.&nbsp; Except for the express
            warranties in this Contract (including an Order Form), the Products
            are provided&nbsp; “as is'' and we disclaim all other warranties,
            express or implied, with respect to the Products and&nbsp; Services,
            including but not limited to, any warranty of merchantability,
            fitness for a particular purpose,&nbsp; accuracy, reliability,
            course of dealing, course of performance or usage in trade.&nbsp;
          </p>

          <p>
            We will not be liable&nbsp; for any claims or damages caused by the
            unauthorized use of the Products or acts of abuse or misuse&nbsp; by
            Client or End Users. In addition, we will not be liable for any loss
            or damage in connection with or&nbsp; arising out of the
            interruption or loss of use of the Products or the loss or
            corruption of your data or&nbsp; files processed or stored by the
            Products, unless such loss or damage is caused by our
            (Transformd's)&nbsp; act, omission or negligence. &nbsp;
          </p>

          <p>
            <strong>9. </strong>
            <strong>Liability &nbsp;</strong>
          </p>

          <p>9.1 Infringement Claims &nbsp;</p>

          <p>
            9.1.1 Subject to the rest of this clause 9, we will arrange for the
            defence or settlement of any&nbsp; Infringement Claim and we shall
            pay or arrange for the payment of your reasonable legal fees and
            any&nbsp; damages and costs awarded against you in respect of such
            Infringement Claim (but we shall not be&nbsp; liable for any
            indirect or consequential loss or damage arising in connection with
            such Infringement&nbsp; Claim). You must: &nbsp;
          </p>

          <p>
            a. promptly notify us in writing of any Infringement Claim; &nbsp;
          </p>

          <p>b. cooperate with us to defend any such claim; and &nbsp;</p>

          <p>
            c. not settle or make any admission of liability without our prior
            written consent. You can choose to&nbsp; participate in the defense
            of any claim with your own lawyers, at your own cost. &nbsp;
          </p>

          <p>
            9.1.2 If an Infringement Claim has been made or threatened or we
            consider the Transformed&nbsp; Materials may infringe any third
            party’s rights, we have the option to: &nbsp;
          </p>

          <p>
            a. modify the Transformed Materials (at our cost) so it becomes
            non-infringing; b. obtain a&nbsp; licence for you (at our cost) to
            continue using the Transformd Materials; or &nbsp;
          </p>

          <p>
            c. if we determine that neither of these options is feasible, we may
            terminate the licence of the&nbsp; infringing Transformd Material
            and refund the licence Charges you have paid for the
            infringing&nbsp; Transformd Material. &nbsp;
          </p>

          <p>
            9.1.3 We will have no liability or obligation for, and shall be
            indemnified by you against, all loss&nbsp; expense &nbsp;
          </p>

          <p>
            a. use of the Product or other materials outside of the scope of the
            Licence or in breach of this&nbsp; Contract;&nbsp;
          </p>

          <p>
            9.1.4 This clause 9.1 constitutes our sole liability and your sole
            remedy (whether under this Contract&nbsp; or otherwise) in
            connection with any actual, suspected or alleged infringement of any
            third party&nbsp; Intellectual Property Rights. &nbsp;
          </p>

          <p>9.2 Exclusion of Indirect Loss &nbsp;</p>

          <p>
            Each party excludes all liability for any loss of product, loss of
            data, loss of revenue, loss of profit, loss&nbsp; of or damage to
            reputation, loss of anticipated savings or benefits, or any
            indirect, special or punitive&nbsp; loss, damage, cost or expense or
            other claims for consequential compensation, incurred by or
            awarded&nbsp; against the other party arising directly or indirectly
            under or in connection with this Contract or the&nbsp; performance
            or non-performance of this Contract and whether arising under any
            indemnity, statute, in tort (for negligence or otherwise), or on any
            other basis in law or equity, except for: interest due by&nbsp; any
            party for late payments; personal injury or tangible property
            damage, losses relating to fraud or&nbsp; wilful misconduct, breach
            of confidence, breach of privacy or infringement of Intellectual
            Property&nbsp; Rights. &nbsp;
          </p>

          <p>9.3 Limits &nbsp;</p>

          <p>
            9.3.1 Our liability to you for all claims relating to Products or
            Services is limited, at our option, to: &nbsp;
          </p>

          <p>
            a. in the case of services, the cost of supplying the services again
            or payment of the cost of&nbsp; having the services supplied again;
            and &nbsp;
          </p>

          <p>
            b. in the case of goods, the cost of replacing the goods, supplying
            equivalent goods or having the&nbsp; goods repaired, or payment of
            the cost of replacing the goods, supplying equivalent goods or&nbsp;
            having the goods repaired. &nbsp;
          </p>

          <p>
            9.3.2 You agree that except for claims under clause 9.1, clause 9.2
            and clause 9.6, our total liability&nbsp; arising out of, connected
            with, or resulting from the performance or non-performance of this
            Contract,&nbsp; whether based on contract, warranty, tort (including
            negligence), statute or otherwise, is capped at&nbsp; the amounts
            you have paid us under this Contract during the 12 months before the
            events giving rise&nbsp; to your claim. &nbsp;
          </p>

          <p>9.4 Contribution &nbsp;</p>

          <p>
            Each party’s liability shall be reduced proportionately to the
            extent that the other party’s acts or&nbsp; omissions cause or
            contribute to, directly or indirectly, the loss or damage for which
            the first party&nbsp; is liable. &nbsp;
          </p>

          <p>9.5 Excused Performance &nbsp;</p>

          <p>
            Each party will not be liable for, or be considered to be in breach
            of or default under this Contract&nbsp; (except an obligation to pay
            money) on account of, any delay or failure to perform as required by
            this&nbsp; Contract as a result of any cause or condition beyond
            that party’s reasonable control. &nbsp;
          </p>

          <p>9.6 Indemnity - third parties&nbsp;</p>

          <p>
            We agree to indemnify and hold you (and your directors, officers,
            employees and agents), harmless&nbsp; from and against any and all
            losses, damage, liabilities, penalties, court cost, professional
            fees&nbsp; (including legal fees) and expense incurred by you
            resulting or arising in whole or in part from any act&nbsp; or
            omission by any third party integration service provider (e.g.
            OneSpan, Green ID).&nbsp;
          </p>

          <p>
            <strong>10. </strong>
            <strong>
              Term and termination
              <br />
            </strong>
          </p>

          <p>10.1 Term &nbsp;</p>

          <p>
            This Contract begins on the date it is formed under clause 1.1 and
            continues for the Initial Term and&nbsp; then continues for as long
            as you pay the Charges for Support and Subscription Services (or
            unless&nbsp; otherwise terminated under clause 10.2). &nbsp;
          </p>

          <p>10.2 Breach or Insolvency &nbsp;</p>

          <p>
            Either party may terminate this Contract if the other party: &nbsp;
          </p>

          <p>
            a. breaches this Contract and does not remedy the breach within two
            weeks of written notice; &nbsp;
          </p>

          <p>
            b. breaches any term of this Contract which cannot be remedied
            (including a breach of&nbsp; confidentiality or Intellectual
            Property Rights); or
            <br />
            &nbsp;&nbsp;
          </p>

          <p>
            c. becomes subject to an event of insolvency (including having an
            administrator, receiver or&nbsp; liquidator appointed). &nbsp;
          </p>

          <p>10.3 Obligations on termination &nbsp;</p>

          <p>When this Contract is terminated or expires: &nbsp;</p>

          <p>
            a. hold the Discloser’s Confidential Information in strict
            confidence, and apply at least the standard&nbsp; of care used by
            the Recipient in protecting its own Confidential Information, but
            not less than&nbsp; a reasonable standard of care; &nbsp;
          </p>

          <p>
            b. not disclose such Confidential Information to any third party,
            except as permitted under this&nbsp; Contract; &nbsp;
          </p>

          <p>
            c. The License will terminate, you will immediately stop using the
            applicable Products. If requested,&nbsp; you will certify in writing
            to us that you have complied with these obligations. &nbsp;
          </p>

          <p>
            10.3.2 Each party must immediately return (or certify that it has
            destroyed) all of the other party’s&nbsp; Confidential Information,
            except for any information which the recipient needs to retain in
            order to&nbsp; perform any ongoing obligation under this Contract
            (and any archive or back-up copies, subject to&nbsp; ongoing
            confidentiality restrictions). &nbsp;
          </p>

          <p>
            10.3.3 Any clause which by its terms is intended to survive the
            expiration or termination of this&nbsp; Contract will do so. &nbsp;
          </p>

          <p>
            10.3.4 Each party retains its rights under this Contract and at law
            in respect of any breach of this&nbsp; Contract by the other party.
            &nbsp;
          </p>

          <p>
            <strong>11. </strong>
            <strong>General &nbsp;</strong>
          </p>

          <p>11.1 Independent Contractors &nbsp;</p>

          <p>
            The parties are independent contractors, not employees, agents,
            partners, franchisees, or&nbsp; representatives of each other. Each
            party is not authorized to, and will not attempt to, create or&nbsp;
            assume any obligation or liability, express or implied, in the name
            or otherwise on behalf of the other&nbsp; party. &nbsp;
          </p>

          <p>11.2 Construction &nbsp;</p>

          <p>
            In interpreting this Contract, no presumption shall be made against
            the party that drafted the term.&nbsp; The singular includes the
            plural and vice versa. Words like <em>including</em>,{" "}
            <em>for example</em>, <em>such as </em>or similar&nbsp; expressions
            are to be interpreted as meaning{" "}
            <em>including, without limitation</em>. Headings are for&nbsp;
            convenience only and do not affect the interpretation of this
            contract. Any notices required under this&nbsp; Contract must be in
            the English language. &nbsp;
          </p>

          <p>11.3 Severability &nbsp;</p>

          <p>
            If a clause or part of a clause of this document can be read in a
            way that makes it illegal, unenforceable&nbsp; or invalid, but can
            also be read in a way that makes it legal, enforceable and valid, it
            must be read in&nbsp; the latter way. If any clause or part of a
            clause is illegal, unenforceable or invalid, that clause or part
            is&nbsp; to be treated as removed from this document, but the rest
            of this document is not affected. &nbsp;
          </p>

          <p>11.4 Variation &amp; Waiver &nbsp;</p>

          <p>
            No variation of this document will be of any force or effect unless
            it is in writing and signed by the&nbsp; parties to this document.
            The fact that a party fails to do, or delays in doing, something the
            party is&nbsp; entitled to do under this document, does not amount
            to a waiver of any obligation of, or breach of&nbsp; obligation by,
            another party. A waiver by a party is only effective if it is in
            writing. A written waiver by&nbsp; a party is only effective in
            relation to the particular obligation or breach in respect of which
            it is given.&nbsp; It is not to be taken as an implied waiver of any
            other obligation or breach or as an implied waiver of&nbsp; that
            obligation or breach in relation to any other occasion. &nbsp;
          </p>

          <p>11.5 Governing law &nbsp;</p>

          <p>
            This Contract and performance under it shall be governed by the laws
            of New South Wales without&nbsp; regard to its choice of law
            principles. Each party unconditionally accepts the jurisdiction of
            the courts&nbsp; of New South Wales and any related appellate court
            provided that each Party shall have the right to&nbsp; enforce a
            judgment of those courts in a jurisdiction in which the other Party
            is incorporated or in which&nbsp; any assets of the other Party may
            be situated. &nbsp;
          </p>

          <p>11.6 Modern Slavery &nbsp;</p>

          <p>
            (i) We will ensure that we and each of our subcontractors take
            reasonable steps to review our supply&nbsp; chains and business for
            modern slavery or human trafficking, and assist You with your
            compliance with&nbsp; Modern Slavery Laws. &nbsp;
          </p>

          <p>
            (ii) We will notify You as soon as we become aware of any material
            actual or suspected breach of a&nbsp; Modern Slavery Law or modern
            slavery or human trafficking in a supply chain which has a
            connection&nbsp; with this Contract. &nbsp;
          </p>

          <p>
            (iii) You have a right to terminate this Contract with prior notice
            to us in the event we have caused or&nbsp; contributed to adverse
            human rights impacts and failed to address these impacts.
            <br />
          </p>

          <p>
            <strong>Schedule 1 - Support Services &nbsp;</strong>
          </p>

          <p>
            <strong>1. </strong>Overview of Support Services &nbsp;
          </p>

          <p>Support Services consist of: &nbsp;</p>

          <p>a. Online Service Centre – see section 2; &nbsp;</p>

          <p>b. Enhancements – see section 3, and; &nbsp;</p>

          <p>c. Error correction – see section 4. &nbsp;</p>

          <p>
            <strong>2. </strong>Online Service Centre &nbsp;
          </p>

          <p>
            Transformd provides an Online Service Centre to assist Clients with
            the use of the Products. &nbsp;
          </p>

          <p>
            Support can be requested via the following Online Service Centre
            portal at&nbsp; https://support.transformd.com (or other URL
            provided from time to time). &nbsp;
          </p>

          <p>
            The preferred method of communications is via the online Service
            Centre. All&nbsp; communications will be in English. &nbsp;
          </p>

          <p>
            Client will appoint a principal contact to coordinate Support
            Services within Client's organisation&nbsp; and to act as a single
            point of contact with Transformd’s Support team. A power user may be
            the&nbsp; single point of contact for multiple sites within the
            Client's organisation. &nbsp;
          </p>

          <p>
            The Online Service Centre can be accessed by Client’s power users
            who have registered to receive&nbsp; Support Services. Self
            registration is via the Online Support Centre at &nbsp;
          </p>

          <p>https://support.transformd.com .&nbsp;</p>

          <p>
            An ‘incident’ is a single issue, problem, error or question. If you
            have more than one incident,&nbsp; please log each one individually.
            Once the incident is logged, a Customer Support Consultant&nbsp;
            will assess your incident and respond accordingly. You can also
            access the Online Service&nbsp; Centre to: &nbsp;
          </p>

          <p>● Gain information relating to future release schedules; &nbsp;</p>

          <p>
            ● Gain access to existing Tip Sheets and Frequently Asked Questions;
            <br />● Log&nbsp; suggestions and comments for consideration in
            future versions. &nbsp;
          </p>

          <p>
            <strong>3. </strong>Enhancements &nbsp;
          </p>

          <p>
            Support Services includes receiving ‘Enhancements’, which means
            newer versions of Products if&nbsp; and when developed and generally
            released by Transformd in its discretion. Typically, major releases
            are released once a year and minor releases may be released&nbsp;
            throughout the year. Patch releases may be released between minor
            releases to address specific&nbsp; functionality issues. &nbsp;
          </p>

          <p>
            <strong>4. </strong>Correcting Errors &nbsp;
          </p>

          <p>
            Support Services includes correcting ‘Errors’, which means where the
            base version of the&nbsp; Software fails to perform in a material
            way with the documentation provided by Transformd. &nbsp;
          </p>

          <p>
            Transformd shall use all reasonable endeavors to correct any Error
            in the Software, with a level of&nbsp; effort commensurate with the
            Error Severity Levels as determined by Transformd, within the
            Response&nbsp; Time, identified in section 5 below. &nbsp;
          </p>

          <p>
            Correcting an Error may require Transformd to provide release
            updates to the platform. &nbsp;
          </p>

          <p>4.1 Support Procedures &nbsp;</p>

          <p>
            Transformd will log all problems with the Software reported by
            Client in accordance with the&nbsp; procedure set out in this
            Schedule. &nbsp;
          </p>

          <p>
            Upon identification of any Error, Client shall provide Transformd
            with enough information to&nbsp; reproduce the Error. If Transformd
            is unable to replicate the Error, Transformd will request a&nbsp;
            second sample. If Transformd is still unable to replicate the Error
            from this second sample then&nbsp; Transformd will close the
            reported Error. &nbsp;
          </p>

          <p>
            Transformd will notify Client as soon as possible if it determines
            that no Error exists or if Transformd&nbsp; is unable to reproduce
            the reported Error. &nbsp;
          </p>

          <p>4.2 Exclusions From Support &nbsp;</p>

          <p>
            Transformd shall have no obligation to provide Support in connection
            with any&nbsp; error, questions or problems that arise from: &nbsp;
          </p>

          <p>
            a. any modification, customization, alteration or addition to
            Software, or attempt thereof, made&nbsp; by Client &nbsp;
          </p>

          <p>b. use of the Software:&nbsp;</p>

          <p>
            i. in a manner other than described in the user help menus; &nbsp;
          </p>

          <p>
            ii. in conjunction with software, equipment, or an operating
            environment that was not the&nbsp; Environment; or &nbsp;
          </p>

          <p>iii. in any way not permitted under the Contract&nbsp;</p>

          <p>c. the negligence or misconduct of any User; &nbsp;</p>

          <p>
            d. the malfunction of any equipment, connectivity or data sources
            not provided by Transformd;&nbsp; e. failure by Client to implement
            reasonable recommendations in respect of or solutions to&nbsp;
            Errors previously advised by Transformd; &nbsp;
          </p>

          <p>
            f. the creation or correction of custom components or API
            integration issues. © 2020. Confidential&nbsp;
          </p>

          <p>
            <strong>5. </strong>Severity Levels and Response Times &nbsp;
          </p>

          <p>5.1 Incident Severity Levels &nbsp;</p>

          <p>
            Incidents will be categorized and actioned according to an assigned
            severity level. &nbsp;
          </p>

          <figure class="wp-block-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Severity Level &nbsp;</strong> Level 1 -
                    Critical&nbsp;
                  </td>
                  <td>
                    <strong>Description &nbsp;</strong> Software is unavailable
                    and cannot be accessed or used.
                  </td>
                </tr>
                <tr>
                  <td>Level 2 - High&nbsp;</td>
                  <td>
                    Software as a whole can be accessed but one or more of the
                    services cannot&nbsp; be accessed and users cannot carry out
                    their planned work
                  </td>
                </tr>
                <tr>
                  <td>Level 3 - Medium&nbsp;</td>
                  <td>
                    An incident that is inconvenient for the user, but where
                    there is a&nbsp; workaround that does not disrupt the
                    planned workload.
                  </td>
                </tr>
                <tr>
                  <td>Level 4 - Low&nbsp;</td>
                  <td>
                    An incident which has a minor impact on the Software which
                    can be&nbsp; endured without interruption to the planned
                    workload.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>

          <p>5.2 Response Times &nbsp;</p>

          <p>
            Response times vary based on the “Hours of operation” and Support
            Levels as nominated in the&nbsp; relevant Product Order Form. The
            response times are measured and applicable from when the
            Client&nbsp; receives a ticket number until an agent engages the
            Client to resolve or gather further information.&nbsp;
          </p>

          <figure class="wp-block-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Severity Level&nbsp;</strong>
                  </td>
                  <td>
                    <strong>Standard Support Target Response Time&nbsp;</strong>
                  </td>
                </tr>
                <tr>
                  <td>1&nbsp;</td>
                  <td>4 hours &nbsp;</td>
                </tr>
                <tr>
                  <td>2&nbsp;</td>
                  <td>8 hours</td>
                </tr>
                <tr>
                  <td>3&nbsp;</td>
                  <td>1 day &nbsp;</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>2 days</td>
                </tr>
              </tbody>
            </table>
          </figure>

          <p>5.3 Service Level Procedure&nbsp;</p>

          <p>
            For all Incident Severity Levels, the following procedure applies:
            &nbsp;
          </p>

          <p>
            a. Incidents must be reported by logging in via
            https://support.transformd.com and selecting&nbsp; ‘Submit a
            request’ or anonymously via https://support.Transformd.com and
            selecting ‘Ticket’.
            <br />
            b. Upon submitting the ticket, the Client will receive a ticket
            number via confirmation email.&nbsp; Response times are applicable
            from receipt of this ticket number. The ticket will be reviewed by
            the Support Centre Representative to properly&nbsp; categorize
            according to the Incident Severity Level. The Client will be
            notified if the Incident&nbsp; Severity Level is amended and
            provided with reasons for this change. &nbsp;
          </p>

          <p>
            c. The Support Centre Representative will notify the Client of a
            resolution or work around for the&nbsp; error or incident. This may
            involve an upgrade to the platform. If no solution is known,
            the&nbsp; Support Centre Representative will request further
            information to diagnose or replicate the&nbsp; described incident.
            &nbsp;
          </p>

          <p>
            d. The Support Centre Representative will provide the Client with a
            Service Request Status&nbsp; response. The response will be one of
            the following: &nbsp;
          </p>

          <p>● “Under investigation” &nbsp;</p>

          <p>● “Waiting” &nbsp;</p>

          <p>● “Resolved” &nbsp;</p>

          <p>
            e. Should delays occur in addressing the ticket, the Client may
            escalate by contacting their&nbsp; Account Manager &nbsp;
          </p>

          <p>
            <strong>6. </strong>Additional Services &nbsp;
          </p>

          <p>
            Items which are not included in Support Services may be provided as
            an Additional Service, for an&nbsp; additional Charge. These may
            include: &nbsp;
          </p>

          <p>
            ● Diagnosing and correcting reported non-conformities which are not
            Errors in the platform or&nbsp; which relate to the matters
            described in section 4.2; &nbsp;
          </p>

          <p>
            ● Any other support or services of any nature not explicitly
            detailed in the Support Services&nbsp; agreement. &nbsp;
          </p>

          <p>
            Client shall reimburse Transformd, at Transformd ’s then current
            professional services rates, for all&nbsp; time spent
            troubleshooting an incident that Transformd determines was caused by
            one of the&nbsp; conditions set forth in section 4.2, but was not
            identified as such until after Transformd began&nbsp;
            troubleshooting the incident.&nbsp;
          </p>
        </Col>
      </Row>
    </Container>
  </MainLayout>
)

export default PlatformPage


