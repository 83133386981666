import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import Integrations from "../../containers/integrations"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const IntegrationsPage = () => (
  <MainLayout>
    <SEO title="Personalised Customer Experience" />
    <Title
      fixedHeight={false}
      headline=" Third Party Integrations"
      subTitle="Platform"
      variant="orange"
    />

    <SectionPane className="pt-0" variant="orange">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bd%5D-integrations.png"
          alt="Popular and powerful integrations"
          className="d-none d-md-inline-block"
        />

        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/3-%5Bm%5D-integrations.png"
          alt="Popular and powerful integrations"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container className="pt-3 pt-md-5">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
            Connect and integrate with major platforms for more control over your experience. 
            With the ability to talk to most major platforms – Transformd can access relevant data for a frictionless customer experience.              
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <h4 className="mb-0 text-center text-md-left">
              Ready, set, integrate
            </h4>
            <p className="text-center text-md-left">
              Transformd takes the complexity out of third party form integrations, meaning you can focus more on what matters most - your customers. 
              Connect to data services, IT applications and major platforms to eliminate friction and provide powerful capability.
            </p>

            <p className="text-center text-md-left">
              Transformd has many inbuilt integrations with data services and
              other IT applications that eliminate friction and provide powerful
              capabilities in serving your customers seamlessly.
            </p>
          </Col>
          <Col md="5">
            <h4 className="mb-0 text-center text-md-left">
              We’ll work with your platform
            </h4>
            <p className="text-center text-md-left">
              Our developed API framework allows for integration with almost any application. 
              Getting started can take a matter of days as we incorporate your user experience design and IT systems. 
              Once integrated, you can start using Transformd in minutes to create simpler, easier and better customer experiences.
            </p>

            <p className="text-center text-md-left">
              Once integrated, APIs can be included in experiences within
              minutes through the drag and drop dashboard.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <Container>
        <Integrations />
      </Container>
    </SectionPane>

    <SectionPane variant="light">
      <ProductBenefits id="integrations" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(IntegrationsPage)
