import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const imgautomate = require("./../../themes/transformd/img/automate.svg")
const imgbuild = require("./../../themes/transformd/img/create.svg")
const imgelevate = require("./../../themes/transformd/img/elevate.svg")

const TransformdStaticPlatform = () => (
  <Container>
    <Row>
      <Col md={{ span: 8, offset: 2 }} className="platform-style">
        <h2 className="h2 text-center text-md-center">
          Create Leading Customer Experiences
        </h2>
        <p>
          Automate workflows, convert paper, PDFs, and manual processes into fully branded customer journeys for any use case.
          Design, build and launch digital customer processes, and continually optimize with advanced analytics.
          The Transformd platform improves the customer experience, cuts operational costs, and increases productivity.
        </p>
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 4 }} className="divplat">
        <img className="mt-3 text-center" src={imgbuild} alt="Build" />
        <h2 className="h2 mt-1">Build</h2>
        <h3 className="h3 mt-2">
          <b>Drag, drop </b>and <b> configure </b>to build customer experiences.
        </h3>
      </Col>
      <Col md={{ span: 4 }} className="divplat">
        <img className="mt-3 text-center" src={imgautomate} alt="Automate" />
        <h2 className="h2 mt-1">Automate</h2>
        <h3 className="h3 mt-2">
          <b>Connect </b>and <b>automate </b>business technologies and
          workflows.
        </h3>
      </Col>
      <Col md={{ span: 4 }} className="divplat">
        <img className="mt-3" src={imgelevate} alt="Elevate" />
        <h2 className="h2 mt-1">Elevate</h2>
        <h3 className="h3 mt-2">
          <b>Deploy, analyse </b>and <b>optimise</b> as often as you need.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 12 }}>
        <h3 className="mt-2 Findout">Scroll down to find out how</h3>
      </Col>
    </Row>
  </Container>
)
export default TransformdStaticPlatform
