import React from "react"
import { Container } from "react-bootstrap"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import SectionPane from "../components/page/SectionPane"
import Title from "../components/page/Title"
import Map from "../components/contact/Map"
import Contact from "../containers/contact"
import globalEmitter from "../components/vendor/GlobalEmitter"

const ContactPage = () => {
  React.useEffect(() => {
    window.getTransformdEmitter = globalEmitter
  }, [])
  return (
    <MainLayout className="header--dark">
      <SEO title="Contact us" />
      <Title
        headline="How can we help?"
        subTitle="Contact"
        variant="light"
        className="pb-0"
        size="sm"
      />

      <SectionPane className="pb-md-5" variant="light">
        <Container className="pb-md-5">
          <Contact globalEmitter={globalEmitter} />
        </Container>
      </SectionPane>

      <Map className="pt-0" />
    </MainLayout>
  )
}

export default ContactPage
