import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const FooterCopyright = () => (
  <div className="footer-copyright d-flex align-items-center">
    <Container>
      <Row>
        <Col xs="12" md="8" className="text-center text-md-left pt-5 pt-md-0">
          © Transformd. All rights reserved.
        </Col>
        <Col xs="6" md="2" className="text-center my-5 text-md-right my-md-0">
          <Link to="/privacy-policy/" className="text-white ">
            Privacy Policy
          </Link>
        </Col>
        <Col xs="6" md="2" className="text-center my-5 text-md-right my-md-0">
          <Link to="/terms-of-business/" className="text-white">
            Terms of Business
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)

export default FooterCopyright
