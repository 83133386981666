import React from "react"
import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import { Container, Row, Col } from "react-bootstrap"

const PrivacyPolicyPage = () => (
  <MainLayout>
    <SEO title="Privacy Policy" />
    <Title headline="Transformd Privacy Policy" subTitle="July 2021" />

    <Container className="px-4">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <p>
            <strong>Transformd Internal Privacy Policy</strong>
          </p>

          <p>
            <strong>1.0 Purpose&nbsp;</strong>
          </p>

          <p>
            The purpose of this policy is to satisfy the regulatory notification
            requirements as well as to demonstrate our commitment to
            safeguarding and appropriately handling our employees’ and
            contractors’ personal information.&nbsp;
          </p>

          <p>This Internal Privacy policy ensures that Transformd:</p>

          <ul>
            <li>
              Complies with data privacy requirements and follows good practices
            </li>
            <li>
              Informs employees and contractors of their privacy rights and
              Transformd’s information handling practices.
            </li>
          </ul>

          <p>
            <strong>2.0 Scope&nbsp;</strong>
          </p>

          <p>
            The Internal Privacy Policy applies to all employees, workers and
            contractors of Transformd (collectively referred to as
            “Individuals”). It applies to all data that the company holds
            relating to identifiable individuals and it describes how Transformd
            collects and uses personal information during and after their
            working relationship with Transformd, in accordance with the
            following applicable information protection legislation:
          </p>

          <ul>
            <li>
              For Australian-based employees:
              <ul>
                <li>
                  applicable provisions of the <em>Privacy Act 1988&nbsp;</em>
                  (Cth).&nbsp;
                </li>
                <li>
                  applicable provisions of the <em>Fair Work Act 2009</em>{" "}
                  (Cth).
                </li>
              </ul>
            </li>
          </ul>

          <p>
            Transformd has decided to apply the highest regulatory standards in
            dealing with personal information uniformly across all our offices,
            regardless of regulatory requirements, while at the same time
            complying with any specific, local privacy requirements.
          </p>

          <p>
            <strong>
              3.0 What personally identifiable information (PII) we collect
            </strong>
          </p>

          <p>
            Personally identifiable information (PII) means any information
            about an individual from which that person can be identified. It
            does not include information where the identity has been removed
            (anonymous information).
          </p>

          <p>
            We collect, store and use the following categories of personal
            information:
          </p>

          <ul>
            <li>
              Personal contact details such as name, title, addresses, telephone
              numbers, and personal email addresses.
            </li>
            <li>
              Personal identity details such as date of birth, marital status,
              sex, gender identity, veteran status, and ethnicity.&nbsp;
            </li>
            <li>
              Dependent details such as name, Tax File Number, date of birth,
              gender, marital status, student status, address, and phone number.
            </li>
            <li>
              Next of kin, emergency contact and death benefit nominee(s)
              information.
            </li>
            <li>
              Tax File Number and other government/state-issued IDs.&nbsp;
            </li>
            <li>Payroll records and tax status information.</li>
            <li>Salary, annual leave, and benefits information.</li>
            <li>Start date, leaving date.</li>
            <li>Location of employment or workplace.</li>
            <li>
              Relevant proof of identification to verify your identity and
              eligibility to work (e.g., passport, driving license).
            </li>
            <li>
              Recruitment information (including copies of right to work
              documentation, references and other information included in a
              resumé or cover letter or as part of the application process).
            </li>
            <li>Compensation history.</li>
            <li>Performance and appraisal information.</li>
            <li>Disciplinary and grievance information.</li>
            <li>
              Information about the use of our information and communications
              systems.
            </li>
            <li>Photographs, videos.</li>
            <li>
              Incident register, first aid records, injury at work and third
              party accident information.
            </li>
            <li>
              Information about convictions/allegations of criminal behaviour.
            </li>
          </ul>

          <p>
            Transformd typically collects personal information about employees,
            workers and contractors through the application and recruitment
            process, either directly from candidates or sometimes from an
            employment agency or background check provider.&nbsp;
          </p>

          <p>
            Transformd will sometimes collect additional information from third
            parties, including former employers, credit reference agencies or
            other background check agencies.
          </p>

          <p>
            Transformd collects additional personal information in the course of
            job-related activities throughout the employment period or life of
            the contract.
          </p>

          <p>
            Transformd limits the collection of personal information to what is
            strictly necessary to establish, manage or terminate the employment
            or contractual relationship with its workers. If Transformd needs to
            collect additional personal information for reasons outside
            establishing, managing or terminating the employment or contractual
            relationships, we will notify the appropriate individuals in advance
            and obtain their consent.&nbsp;
          </p>

          <p>
            Applicable privacy legislation may allow us to collect personal
            information about individuals without their consent in specific
            circumstances, such as investigations or legal proceedings.&nbsp;
          </p>

          <p>
            <strong>
              4.0 Individual's responsibility to inform Transformd of changes
            </strong>
          </p>

          <p>
            It is important that the personal information we hold about our
            workers is accurate and current. Individuals are responsible for
            keeping Transformd informed if their personal information changes
            during their working relationship with us.
          </p>

          <p>
            <strong>5.0 How we use personal information&nbsp;</strong>
          </p>

          <p>
            Transformd will only use personal information as permitted by the
            applicable legislation.&nbsp;&nbsp;
          </p>

          <p>
            Most commonly, Transformd will use personal information in the
            following circumstances:
          </p>

          <ul>
            <li>Making a decision about recruitments or appointments.</li>
            <li>Determining the terms of working agreements.</li>
            <li>
              Checking that individuals are legally entitled to work in the
              jurisdiction.
            </li>
            <li>
              Paying individuals and, if applicable, deducting tax and any other
              social contributions.
            </li>
            <li>
              Providing employment-related benefits including:
              <ul>
                <li>
                  Occupational Sick, Adoption, Maternity, Paternity, Shared
                  Parental and Annual leave
                </li>
                <li>Salary, including any pay awards or allowances&nbsp;</li>
                <li>Superannuation</li>
                <li>Advances of salary</li>
              </ul>
            </li>
            <li>
              Liaising with superannuation providers, providing information
              about changes to employment that may impact salaries (e.g.,
              promotion, changes in working hours).
            </li>
            <li>General administration of the employment or work contract.</li>
            <li>
              Business management and planning, including accounting and
              auditing.
            </li>
            <li>
              Conducting performance reviews, managing performance and
              determining performance requirements.
            </li>
            <li>Making decisions about salary reviews and compensation.</li>
            <li>
              Assessing qualifications for a particular job/task, including
              decisions about promotions.
            </li>
            <li>
              Gathering evidence and dealing with grievances, disciplinary
              matters, etc.
            </li>
            <li>Making decisions about continued employment or engagement.</li>
            <li>
              Making arrangements for the termination of working relationships.
            </li>
            <li>Education, training and development requirements.</li>
            <li>
              Dealing with legal disputes involving Transformd workers,
              including accidents at work.
            </li>
            <li>
              Ascertaining workers fitness to work, managing sickness absence.
            </li>
            <li>Complying with health and safety obligations.</li>
            <li>To prevent fraud.</li>
            <li>
              To monitor workers' business and personal use of Transformd’s
              information and communication systems to ensure compliance with
              our IT policies, such as emails, drives, business communication
              platforms (like Slack, etc) laptop folders or any other resources
              provided.
            </li>
            <li>
              To ensure network and information security, including preventing
              unauthorised access to Transformd computer and electronic
              communications systems and preventing malicious software
              distribution.
            </li>
            <li>
              To conduct information analytics studies to review and better
              understand employee retention and attrition rates.
            </li>
            <li>Dealing with any subject rights requests.</li>
            <li>To provide employment references at workers' request.</li>
            <li>
              To carry Transformd’s HR-related investigations, solving internal
              disputes, etc.
            </li>
          </ul>

          <p>
            Transformd will only use personal information to establish, manage
            or terminate the employment or contractual relationships.
          </p>

          <p>
            If Transformd needs to use personal information for an unrelated or
            new purpose, Transformd will notify the appropriate individual and
            obtain their consent for the new purpose. Individuals always have
            the right to withdraw their consent at any time.&nbsp;
          </p>

          <p>
            Please note that Transformd will, if necessary, process personal
            information without the individual’s knowledge or consent as allowed
            by the applicable privacy legislation in specific circumstances,
            such as investigations or legal proceedings.&nbsp;
          </p>

          <p>
            <strong>6.0 How we share personal information&nbsp;</strong>
          </p>

          <p>
            Transformd will, in some circumstances, have to share information
            with third parties, including third-party service providers.
          </p>

          <p>
            Transformd’s third-party service providers are required by
            contractual obligations to take appropriate security measures to
            protect personal information in line with applicable privacy
            legislation.&nbsp;
          </p>

          <p>
            Transformd does not allow third-party service providers to use
            personal information for their own purposes. Transformd only permits
            them to process personal information for specified purposes and in
            accordance with instructions.
          </p>

          <p>
            Transformd will in some circumstances transfer personal information
            outside the jurisdiction the individual works in. In those cases,
            individuals can expect a similar degree of protection in respect of
            their personal information as that provided under the applicable
            privacy legislation.&nbsp;
          </p>

          <p>We share your personal information with third parties if it’s:</p>

          <ul>
            <li>required by law, and</li>
            <li>
              necessary to establish, manage or terminate the working
              relationship
            </li>
          </ul>

          <p>
            Transformd does not sell the personal information of any employees
            or contractors.
          </p>

          <p>
            <strong>
              6.1 Third-party Service Providers Transformd Uses&nbsp;
            </strong>
          </p>

          <p>
            These are entities which Transformd contracts to support activities
            for HR and payroll purposes:
          </p>

          <p>Australia</p>

          <ul>
            <li>Payroll (Monocle Bookkeeping /Xero)</li>
            <li>IT services (Rawtech name of vendor/application)</li>
            <li>Recruitment agencies (Jobfitts name of vendor/application)</li>
          </ul>

          <p>
            <strong>7.0 Information Security</strong>
          </p>

          <p>
            Transformd has put in place appropriate security measures to prevent
            personal information from being accidentally lost, used or accessed
            in an unauthorised way, altered or disclosed.&nbsp;
          </p>

          <p>
            In addition, Transformd limits access to personal information to
            those employees, contractors and other third parties who have a
            business need to know. They will only process personal information
            on Transformd instructions.&nbsp;
          </p>

          <p>
            Transformd has put in place procedures to deal with any suspected
            information security breach and will notify affected individuals and
            any applicable regulator of a suspected breach where legally
            required to do so.
          </p>

          <p>
            <strong>8.0 Information Retention</strong>
          </p>

          <p>
            Transformd will only retain personal information for as long as
            necessary to fulfil the purposes it was collected for, including to
            satisfy any legal, accounting or reporting requirements.&nbsp;
          </p>

          <p>
            To determine the appropriate retention period for personal
            information, Transformd considers the amount, nature, and
            sensitivity of the personal information, the potential risk of harm
            from unauthorised use or disclosure of personal information, the
            purposes for which we process personal information and whether those
            purposes can be achieved through other means, and the applicable
            legal requirements.
          </p>

          <p>
            Transformd will retain and securely destroy the personal information
            of former employees, workers or contractors in accordance with
            defined timelines or applicable laws and regulations.
          </p>

          <p>
            <strong>
              9.0 Individual Rights in Connection with Personal Information
            </strong>
          </p>

          <p>
            Under certain circumstances, by law, individuals have the right to:
          </p>

          <ul>
            <li>
              Request access to personal information. This enables individuals
              to receive a copy of the personal information Transformd holds
              about individuals and to check that Transformd is lawfully
              processing it.
            </li>
            <li>
              Request correction of the personal information that Transformd
              holds. This enables individuals to have any incomplete or
              inaccurate information Transformd holds corrected.
            </li>
            <li>
              Request erasure of personal information. This enables individuals
              to ask Transformd to delete or remove personal information where
              there is no good reason for continuing to process it.
            </li>
            <li>
              Right to withdraw consent. In the limited circumstances where
              individuals have provided consent to the collection, use and
              transfer of personal information for a specific purpose,
              individuals have the right to withdraw consent at any time. Once
              Transformd has received notification of consent withdrawn,
              Transformd will no longer process information for the purpose or
              purposes the individual originally agreed to.
            </li>
          </ul>

          <p>
            Individuals also have the right to lodge a complaint to the
            responsible authority in their jurisdiction. Please see their
            contact details below.
          </p>

          <ul>
            <li>
              Office of the Australian Information Commissioner (OAIC)&nbsp;
              <br />
              <ul>
                <li>
                  <a href="https://www.oaic.gov.au/">
                    https://www.oaic.gov.au/
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>10.0 Changes to this Privacy Notice</strong>
          </p>

          <p>
            Transformd may update this policy at any time and will provide
            individuals with a new policy when substantial updates are made.
            Transformd will also notify individuals in other ways from time to
            time about the collection, use and sharing of personal information.
          </p>

          <p>
            Any questions about this policy should be directed to direct
            supervisors.
          </p>
        </Col>
      </Row>
    </Container>
  </MainLayout>
)

export default PrivacyPolicyPage


