/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import TransformdTheme from "../../themes/transformd"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Gdpr from "../../components/gdpr"


const MainLayout = ({ children, className, hideFooter }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <TransformdTheme>
        <div className={classNames({ [className]: className })}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          {!hideFooter && <Footer />}
          <Gdpr />
        </div>
      </TransformdTheme>
    )}
  />
)

MainLayout.defaultProps = {
  className: "",
  hideFooter: false,
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideFooter: PropTypes.bool,
}

export default MainLayout
