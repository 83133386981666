import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Title = ({ fixedHeight, headline, size, subTitle, variant }) => (
  <div
    className={classNames(
      `page-title d-flex align-items-center justify-content-center flex-column page-title--size-${size}`,
      { [`page-title--${variant}`]: variant, "page-title--fixed": fixedHeight }
    )}
  >
    {subTitle && <h2 className="sub-title w-100">{subTitle}</h2>}
    <h1 className="h2 md-display-2 headline mb-0">{headline}</h1>
  </div>
)

Title.defaultProps = {
  fixedHeight: true,
  headline: "",
  size: "md",
  variant: "",
}

Title.propTypes = {
  className: PropTypes.string,
  fixedHeight: PropTypes.bool,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  subTitle: PropTypes.string,
  variant: PropTypes.string,
}

export default Title
