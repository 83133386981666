import React from "react"
import { Container } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import SectionPane from "../../components/page/SectionPane"
import Title from "../../components/page/Title"
import Map from "../../components/contact/Map"
import Contact from "../../containers/contact"
import globalEmitter from "../../components/vendor/GlobalEmitter"

const GetStartedPage = () => {
  React.useEffect(() => {
    window.getTransformdEmitter = globalEmitter
  }, [])
  return (
    <MainLayout className="header--dark">
      <SEO title="Contact us" />
      <Title
        headline="How can we help?"
        subTitle="Contact"
        variant="white"
        className="pb-0"
        size="sm"
      />

      <SectionPane className="mb-5">
        <Container>
          <Contact globalEmitter={globalEmitter} initialModal="request-demo" />
        </Container>
      </SectionPane>

      <Map className="pt-5" />
    </MainLayout>
  )
}

export default GetStartedPage
