import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

const Learn = ({ text }) => (

  <Link to="/contact/get-started" className="d-none d-md-inline">
  <Button className="ButtonOp" variant="primary">
    {text}
  </Button>
</Link>


)

Learn.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Learn
