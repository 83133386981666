import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import FooterMenu from "./FooterMenu"
import Logo from "../transformd/Logo"

// @todo data source
const menuLinks = {
  benefits: {
    title: "Organisation Benefits",
    url: "/benefits",
    items: [
      {
        key: "improved-business-outcomes",
        text: "Improved business outcomes",
        url: "/benefits#improved-outcomes",
      },
      {
        key: "operational-efficiency",
        text: "Operational efficiency",
        url: "/benefits#operational-efficiency",
      },
      {
        key: "business-agility",
        text: "Business agility",
        url: "/benefits#business-agility",
      },
      // {
      //   key: "policy-compliance",
      //   text: "Policy & compliance",
      //   url: "/benefits#policy",
      // },
    ],
  },
  caseStudies: {
    title: "Case studies",
    items: [{ key: "tm-bank", text: "TM Bank", url: "/case-studies/tmbank" }],
  },
  contact: {
    title: "Contact us",
    url: "/contact",
    items: [],
  },
  platform: {
    title: "No Code Platform",
    items: [
      {
        key: "personalised-cx",
        text: "Customer Experience Transformation",
        url: "/platform/personalised-cx",
      },
      {
        key: "drag-drop",
        text: "Drag & drop",
        url: "/platform/anyone-can-create",
      },
      {
        key: "easy-share",
        text: "Fully brand customisable",
        url: "/platform/beautiful",
      },
      {
        key: "integrations",
        text: "Third Party Integrations",
        url: "/platform/integrations",
      },

      {
        key: "data-analytics",
        text: "Analytics & Insights",
        url: "/platform/data-analytics",
      },

      {
        key: "platform-security",
        text: "Security",
        url: "/platform/security",
      },
    ],
  },
  solutions: {
    title: "Solutions",
    url: "/solutions",
    items: [
      {
        key: "mutual-bank-applications",
        text: "Mutual Bank applications",
        url: "/solutions/mutual-bank-applications",
      },
      {
        key: "retail-wholesale-funds",
        text: "Wholesale fund applications",
        url: "/solutions/retail-wholesale-funds",
      },
      {
        key: "finance-application-forms",
        text: "Superannuation forms",
        url: "/solutions/finance-application-forms",
      },
    ],
  },
}

const FooterLinks = () => (
  <div className="footer-links pl-4 pr-4">
    <Container>
      <Row>
        <Col xs="12" md="3" className="footer-logo pl-0 pl-md-3">
          <Logo light />
          <p className="text-white mt-2">Transform your digital experiences.</p><p className="text-white">A smarter, faster and easier way to deliver automated end-to-end processes.</p>
        </Col>
        <Col xs="6" md="2" className="mb-3 mb-md-0 pl-0 pl-md-3">
          <FooterMenu {...menuLinks.platform} />
        </Col>
        <Col xs="6" md="3" className="mb-3 mb-md-0 pl-0 pl-md-3">
          <FooterMenu {...menuLinks.benefits} />
        </Col>
        <Col xs="6" md="2" className="pl-0 pl-md-3">
          <FooterMenu {...menuLinks.solutions} />
        </Col>
        <Col xs="6" md="2" className="pl-0 pl-md-3">
          <FooterMenu {...menuLinks.caseStudies} />
          <FooterMenu {...menuLinks.contact} />
        </Col>
      </Row>
    </Container>
  </div>
)

export default FooterLinks
