import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import Solution from "./components/Solution"

const SolutionsPreview = ({ className, exclude }) => {
  const {
    allSolutionsJson: { edges },
  } = useStaticQuery(
    graphql`
      query {
        allSolutionsJson {
          edges {
            node {
              id
              title
              icon
              shortDesc
            }
          }
        }
      }
    `
  )

  const solutions = edges
    .map(({ node }) => node)
    .filter(({ id }) => !exclude.includes(id))

  return (
    <Row
      className={classNames("solutions-preview", { [className]: className })}
    >
      {solutions.map(({ id, icon, title, shortDesc }) => (
        <Col key={id} md="4">
          <Solution id={id} title={title} shortDesc={shortDesc} image={icon} />
        </Col>
      ))}
    </Row>
  )
}

SolutionsPreview.defaultProps = {
  className: "",
  exclude: [],
}

SolutionsPreview.propTypes = {
  className: PropTypes.string,
  exclude: PropTypes.arrayOf(PropTypes.string),
}

export default SolutionsPreview
