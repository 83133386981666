import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"


const Logo = ({ light, text }) => (
  <div className={`flex-grow-1 logo logo--${light ? "light" : "dark"}`}>
    <Link to={"/"}>
      link
    </Link>
  </div>
)

Logo.defaultProps = {
  light: false,
  text: "Transformd",
}

Logo.propTypes = {
  light: PropTypes.bool,
  text: PropTypes.string,
}

export default Logo
