import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

const Gdpr = () => {
  const [hideGdpr, setHideGdpr] = useState(true)

  function getCookie(name) {
    if (typeof document !== `undefined`) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2)
        return parts
          .pop()
          .split(";")
          .shift()
    }
  }

  useEffect(() => {
    setHideGdpr(getCookie("hideGdpr") ? true : false)
    console.log(getCookie("hideGdpr"))
  }, [])

  if (!hideGdpr) {
    return (
      <div className="gdpr">
        <p>
          We use cookies to help personalize content, tailor and measure ads,
          and provide a safer experience. By navigating the site, you agree to
          the use of cookies to collect information on the Transformd website.
          By accepting our Cookie Policy you allow us to collect and use your
          information on third-party platforms.
        </p>
        <div className="d-flex">
        <Link to="/privacy-policy/" className="flex-grow-1 text-white">Privacy Policy</Link>
        <Link to="/terms-of-business/" className="flex-grow-1 text-white">Terms of Business</Link>
        <button
          type="button"
          className="btn btn-info btn-sm float-right"
          onClick={() => {
            setHideGdpr(true)
            document.cookie = "hideGdpr=true"
          }}
        >
          Accept
        </button>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Gdpr
