import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

import Benefit from "./Benefit"

const getData = (data, randomize) =>
  randomize ? data.sort(() => 0.5 - Math.random()) : data

const sorted = (data, id) => {
  let sorted = [...data]

  const searchOrder = data.find(o => o.node.key === id).node.order
  data.find(o => {
    if (o.node.order <= searchOrder) {
      sorted.push(o)
      sorted = sorted.splice(1)
    }

    return o.node.order === searchOrder
  })

  return sorted
}

const ProductBenefits = ({ amount, data, randomize, id }) => (
  <Container>
    <Row className="mt-2 mt-md-4 mb-md-5">
      <Col className="mb-5" md={{ span: 10, offset: 1 }}>
        <p className="h2 md-h1 text-center text-dark mb-0 mb-md-3">
          See more platform features:
        </p>
      </Col>
    </Row>

    <Row>
      {sorted(getData(data, randomize), id)
        .slice(0, amount)
        .map(({ node }, i) => (
          <Col
            md="4"
            className={classNames("mb-md-0", { "mb-5": i < amount - 1 })}
            key={i}
          >
            <Benefit {...node} />
          </Col>
        ))}
    </Row>
  </Container>
)

ProductBenefits.defaultProps = {
  amount: 3,
  randomize: false,
}

ProductBenefits.propTypes = {
  amount: PropTypes.number,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  randomize: PropTypes.bool,
}

export default ProductBenefits
