import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const SecurityPage = () => (
  <MainLayout className="header--dark">
    <SEO title="Beautiful brands, simple to embed" />
    <Title
      fixedHeight={false}
      headline="Secure &amp; private"
      subTitle="Platform"
      variant="medium-grey"
    />

    <SectionPane className="pt-0" variant="medium-grey">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/6-%5Bd%5D-secure.png"
          alt="Secure &amp; private"
          className="d-none d-md-inline-block"
        />
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/6-%5Bm%5D-secure.png"
          alt="Secure &amp; private"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container className="pt-md-5 pt-3">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
              Privacy and security are our number one priority. Know your data
              is securely protected whilst maintaining full ownership and
              control over how you use it.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <p className="text-center text-md-left">
              Transformd provides high performing, high availability services
              and ensure that your data and your customers’ data is secure
              whilst you maintain full ownership and control over it.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Maintain data control
            </h4>
            <p className="text-center text-md-left">
              While your data is stored on our systems, you maintain full
              ownership and control over it. At any time, you can download a
              complete copy or delete it. Designed to comply with relevant
              industry standards: Transformd is ISO27001 Certified.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Complete data encryption
            </h4>
            <p className="text-center text-md-left">
              Sophisticated encryption ensures your data is secure from the
              threat of hackers – both in transit and in storage. When you
              collect it from your customers, we force https and apply ssl. at
              rest. We encrypt using AES256 symmetric encryption.
            </p>
          </Col>
          <Col md="5">
            <h4 className="mb-0 text-center text-md-left">
              World-class infrastructure
            </h4>
            <p className="text-center text-md-left">
              The Amazon Web Services (AWS) infrastructure we use is
              sydney-based and designed by AWS certified engineers who follow
              AWS recommendations and best practises.
            </p>

            <h4 className="mb-0 text-center text-md-left">Risk Management</h4>
            <p className="text-center text-md-left">
              To ensure we are managing risk, Transformd regularly conducts
              third party audit on all of our policies and procedures. We also
              perform penetration tests and have robust business continuity
              management in place to prevent incidents. We ensure high
              availability and mitigate risk with disaster recovery plans.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <ProductBenefits id="security" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(SecurityPage)
