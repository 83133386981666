/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import browserDetect from "browser-detect"
import BodyClassName from "react-body-classname"
import classNames from "classnames"

import TransformdContext from "./context"

import "./styles/main.scss"

class TransformdTheme extends Component {
  state = { browser: "undefined", mobile: false }

  componentDidMount() {
    const { name: browser, mobile } = browserDetect()
    this.setState({ browser, mobile })
  }

  contextualValues = () => ({
    browser: this.state.browser,
    mobile: this.state.mobile,
  })

  render() {
    const { children } = this.props
    const { browser } = this.state

    return (
      <TransformdContext.Provider value={this.contextualValues()}>
        <BodyClassName
          className={classNames({ [`browser--${browser}`]: browser })}
        >
          {children}
        </BodyClassName>
      </TransformdContext.Provider>
    )
  }
}

TransformdTheme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TransformdTheme
