import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
import classNames from "classnames"

const LatLong = {
  lat: -33.862471,
  lng: 151.2083088,
}

const styles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
]

const MapMarker = () => (
  <div className="map-marker-container">
    <div className="map-marker">
      <div className="d-flex">
        <p className="sub-title mb-3">
          Transformd
          <br />
          Headquarters
        </p>
      </div>

      <div className="d-flex align-items-top">
        <p className="mb-2">
          <strong className="pr-2">A:&nbsp;</strong>
        </p>
        <p className="mb-2">
          L4, 6-8 Underwood St
          <br />
          Sydney NSW, 2000
        </p>
      </div>
      <div className="d-flex align-items-top">
        <p className="mb-0">
          <strong className="pr-2">P:&nbsp;</strong>
        </p>
        <p className="mb-0">02 9099 1066</p>
      </div>
    </div>
    <div className="map-marker-arrow" />
  </div>
)

class Map extends Component {
  render() {
    const { className } = this.props

    return (
      <div
        style={{ height: 700, width: "100%" }}
        className={classNames({ [className]: className })}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDeOAcLGSW3DqiJ_0gf0SrhGnijuufxXV8" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles }}
          scrollWheel={false}
          navigationControl={false}
          mapTypeControl={false}
          scaleControl={false}
          draggable={false}
        >
          <MapMarker {...LatLong} />
        </GoogleMapReact>
      </div>
    )
  }
}

Map.defaultProps = {
  center: LatLong,
  className: "",
  zoom: 16,
}

export default Map
