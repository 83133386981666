import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Formatic from "../vendor/Formatic"

const Transformd = ({ globalEmitter }) => {
  return (
    <Container>
      <Row>
        <Col md="5">
          <h2 className="h3 md-display-2 font-weight-bold text-center text-md-left">
            Get Transformd
          </h2>
          <p className="md-h3 text-center text-md-left">
            A seamless, smart and user-friendly solution that will make life
            easier for you and your customers.
          </p>
        </Col>
        <Col md={{ offset: 1, span: 4 }}>
          <Formatic
            globalEmitter={globalEmitter}
            formId={419}
            theme="transformd-ds transformd-ds--white"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Transformd
