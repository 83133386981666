import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import ProductBenefits from "./ProductBenefits"

const query = graphql`
  query ProductBenefitsQuery {
    allBenefitsJson {
      edges {
        node {
          key
          headline
          shortDescription
          img
          url
          order
        }
      }
    }
  }
`

const ProductBenefitsWithData = ({ id }) => (
  <StaticQuery
    query={query}
    render={({ allBenefitsJson: { edges: data } }) => (
      <ProductBenefits data={data} id={id} />
    )}
  />
)

ProductBenefitsWithData.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ProductBenefitsWithData
