import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Integrations from "./Integrations"

const query = graphql`
  query IntegrationsQuery {
    allIntegrationsJson ( sort:{
      fields: logo
    } ) {
      edges {
        node {
          id
          key
          category
          logo
          name
          shortDescription
        }
      }
    }
  }
`

class IntegrationsWithData extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={({ allIntegrationsJson: { edges: data } }) => (
          <Integrations data={data} />
        )}
      />
    )
  }
}

IntegrationsWithData.defaultProps = {
  data: {},
}

IntegrationsWithData.propTypes = {
  data: PropTypes.object,
}

export default IntegrationsWithData
