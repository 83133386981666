import React from "react"
import { Link } from "gatsby"
import { isEmpty } from "lodash"

const ArticlePreview = ({
  title,
  field_slug,
  revisionData: { formatted },
  body: { summary },
  field_image: { alt },
  relationships: {
    field_image: {
      uri: { url },
    },
    field_tags,
  },
}) => (
  <Link to={`/blog/${field_slug}`}>
    <div className="blog-article__preview">
      <div className="preview__cover-container">
        <img src={`https://transformd.fishvision.net.au/${url}`} alt={alt} />
      </div>
      <div className="preview__cover-content">
        <h4>{title}</h4>
        <p>{summary}</p>
        <div className="blog-article__preview-meta">
          <span>Last Updated</span> : <span>{formatted} </span>
        </div>
        <ul className="blog-article__tags">
          {!isEmpty(field_tags) &&
            field_tags.map(({ name }, i) => (
              <li key={i}>
                <span>{name}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  </Link>
)

export default ArticlePreview
