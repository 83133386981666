import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import createDOMPurify from "dompurify"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import SectionPane from "../components/page/SectionPane"

class IntegrationsTemplate extends Component {
  state = { description: "" }

  componentDidMount() {
    // needs to take place in componentDidMount for gatsbyjs SSR
    const {
      data: {
        integrationsJson: { description },
      },
    } = this.props

    const DOMPurify = createDOMPurify(window)
    this.setState({ description: DOMPurify.sanitize(description) })
  }

  render() {
    const {
      data: {
        integrationsJson: { category, logo, name, youtubeUrl },
      },
    } = this.props

    const { description } = this.state

    return (
      <MainLayout className="page-integration">
        <SEO title={`${name} | ${category}`} />
        <Title
          fixedHeight={false}
          headline="Integrations"
          subTitle="Platform"
          variant="orange2"
          size="lg"
        />

        <SectionPane>
          <Container>
            <Row>
              <Col md={{ span: 8, offset: 2 }} className="text-center">
                <p className="font-weight-bold mb-0">Category</p>
                <p className="sub-title mb-5">{category}</p>

                <p className="integration-img pt-4">
                  <img src={logo} alt={name} />
                </p>
                <h1 className="text-dark h3 md-h1">{name}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                {
                  <div>
                    {
                      youtubeUrl && 
                      <h4>Transformd- Docusign Webinar: Increasing Profits and Optimising Operational Efficiencies, October 2020</h4>
                      
                    }
                    {
                      youtubeUrl && <iframe className="integration-video" height="415"  src={youtubeUrl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    }
                    {
                      youtubeUrl && <p>
                        To learn more, please <Link to='/contact'>get in contact</Link> and one of our specialists will be able to assist!
                      </p>
                    }
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </SectionPane>
      </MainLayout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    integrationsJson(key: { eq: $slug }) {
      category
      logo
      name
      description
      youtubeUrl
    }
  }
`

export default IntegrationsTemplate
