import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

import ProductWalkthroughContext from "./context"
import Title from "./Title"

const Walkthrough = ({
  body,
  currentSlide,
  direction,
  firstRender,
  headline,
  img,
  mobileImg,
  slideKey,
  title,
  url,
  variant,
}) => (
  <ProductWalkthroughContext.Consumer>
    {({ windowHeight }) => (
      <div
        className={classNames(
          `walkthrough-slide walkthrough-slide--${variant}`,
          {
            "walkthrough-slide--active": slideKey === currentSlide,
            [`walkthrough-slide--${direction}`]:
              slideKey === currentSlide && direction,
            "walkthrough-slide--first-render": firstRender,
          }
        )}
        style={{ height: `${windowHeight}px` }}
      >
        {title && <Title text={title} />}

        {img && (
          <img
            src={img}
            alt={headline}
            className={classNames("walkthrough-img", {
              "d-none d-md-inline-block": mobileImg,
            })}
          />
        )}

        {mobileImg && (
          <img
            src={mobileImg}
            alt={headline}
            className="walkthrough-img d-inline-block d-md-none"
          />
        )}

        <div className="container px-0">
          <div className="walkthrough-caption d-flex flex-column justify-content-center px-3 px-md-0">
            {headline && (
              <h2 className="h2 md-display-2 walkthrough-slide-title text-center text-md-left mb-2 mb-md-5">
                {headline}
              </h2>
            )}
            {body && (
              <div className="walkthrough-slide-body mb-0 mb-md-4 text-center text-md-left">
                {typeof body === "string" ? body : <p>{body}</p>}
              </div>
            )}

            {url && (
              <div className="walkthrough-slide-link text-center text-md-left">
                <Link to={url} className="btn">
                  Find out more
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </ProductWalkthroughContext.Consumer>
)

Walkthrough.defaultProps = {
  body: "",
  firstRender: false,
  img: "",
  title: "",
  url: "",
  variant: "",
}

Walkthrough.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  currentSlide: PropTypes.string.isRequired,
  direction: PropTypes.string,
  firstRender: PropTypes.bool,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  img: PropTypes.string,
  slideKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  variant: PropTypes.string,
}

export default Walkthrough
