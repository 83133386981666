import React, { Component } from "react"
import PropTypes from "prop-types"
import { Button, Modal } from "react-bootstrap"

import ContactContext from "../context"
import { Option } from "../Contact"
import DemoFrom from "../forms/DemoForm"
import SalesForm from "../forms/SalesForm"
import SupportForm from "../forms/SupportForm"

class ContactForm extends Component {
  render() {
    const { cancelText, option, show, globalEmitter } = this.props
    return (
      <ContactContext.Consumer>
        {({ setActiveOption }) => (
          <Modal
            show={show}
            onHide={() => setActiveOption(null)}
            className="contact-form"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setActiveOption(null)}
            >
              <span aria-hidden="true">×</span>
            </button>

            <Modal.Body>
              {option === Option.Demo && (
                <DemoFrom globalEmitter={globalEmitter} />
              )}
              {option === Option.Sales && (
                <SalesForm globalEmitter={globalEmitter} />
              )}
              {option === Option.Support && (
                <SupportForm globalEmitter={globalEmitter} />
              )}

              {cancelText && (
                <Button
                  variant="light position-absolute cancel-btn"
                  onClick={() => setActiveOption(null)}
                >
                  {cancelText}
                </Button>
              )}
            </Modal.Body>
          </Modal>
        )}
      </ContactContext.Consumer>
    )
  }
}

ContactForm.defaultProps = {
  cancelText: "Cancel",
  globalEmitter: {},
}

ContactForm.propTypes = {
  cancelText: PropTypes.string,
  option: PropTypes.string,
  globalEmitter: PropTypes.object,
}

export default ContactForm
