import React from "react"
import PropTypes from "prop-types"
import { Card, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IntegrationCard = ({ category, shortDescription, logo, name }) => (
  <Card>
    <Card.Body>
      <p className="sub-title mb-0 text-right ">{category}</p>
      <img src={logo} alt={name} className="d-block" />
      <h4 className="text-base-dark">{name}</h4>
      {shortDescription && <p className="mb-0">{shortDescription}</p>}
    </Card.Body>
  </Card>
)

const Integration = ({ nodeKey, ...props }) => (
  <Col md="4" className="integrations-integration mb-4 mb-md-5">
    <Link to={`/platform/integrations/${nodeKey}`}>
      <IntegrationCard {...props} />
    </Link>
  </Col>
)

Integration.defaultProps = {
  description: "",
}

Integration.propTypes = {
  category: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nodeKey: PropTypes.string.isRequired,
  shortDescription: PropTypes.string,
}

export default Integration
