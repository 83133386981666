import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import Title from "../../components/page/Title"
import SectionPane from "../../components/page/SectionPane"
import withFadeIn from "../../hoc/withFadeIn"
import ProductBenefits from "../../containers/product-benefits"

const PersonalisedCxPage = () => (
  <MainLayout>
    <SEO title="Personalised customer experiences" />
    <Title
      fixedHeight={false}
      headline={
        <>Personalised customer experiences</>
      }
      subTitle="Platform"
      variant="primary"
    />

    <SectionPane className="pt-0" variant="primary">
      <div className="text-center">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/1-%5Bd%5D-personalised-cx.png"
          alt="Personalised Customer Experience"
          className="d-none d-md-inline-block"
        />

        <img
          src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/product/1-%5Bm%5D-personalised-cx.png"
          alt="Personalised Customer Experience"
          className="d-inline-block d-md-none"
        />
      </div>

      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3 className="md-h1 text-center mb-5 mt-5">
            Your customers are more than an application number. Show them that you know them by pre-populating data and enabling personalisation throughout the form.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 5, offset: 1 }}>
            <h4 className="mb-0 text-center text-md-left">
              Customer satisfaction, transformd
            </h4>
            <p className="text-center text-md-left">
              Improve your customer satisfaction by creating more meaningful interactions between your business and your customers. 
              Generate personalised screens, email responses and documents for optimal customer engagement.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Multi-participant forms
            </h4>
            <p className="text-center text-md-left">
              Need to collect information from more than one person? Transformd supports multi-participant experiences 
              and makes it easy and seamless for your customers.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Collaborative Experiences
            </h4>
            <p className="text-center text-md-left">
              Improve data quality and enhance customer experience by involving
              multiple participants seamlessly in a single flow.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Cross Device Continuity
            </h4>
            <p className="text-center text-md-left">
              Put it down here, pick it up over there. Whether your customer is on their mobile, 
              laptop or desktop… they won’t lose a keystroke.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Instantly Complete
            </h4>
            <p className="text-center text-md-left">
              Get it right the first time, &amp; every time field structure and
              data verification
            </p>

          </Col>
          <Col md="5">
            <h4 className="mb-0 text-center text-md-left">
              Dynamic Personalisation
            </h4>
            <p className="text-center text-md-left">
              Smart forms know where your customers have come from, and can figure out what they want to do. 
              This means there are fewer hoops to jump through on the way to form completion. 
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Data Pre-Population
            </h4>
            <p className="text-center text-md-left">
              Forget making your customers enter information multiple times. If the data has been previously captured, 
              Transformed will pipe it throughout the form making your customers experience efficient and streamlined.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Documents &amp; Communications
            </h4>
            <p className="text-center text-md-left">
              Enable users to upload documents, dynamically generate
              personalised communications for customers and staff, manage their
              content and ensure delivery to the correct recipients.
            </p>

            <h4 className="mb-0 text-center text-md-left">
              Real Time Data Verification
            </h4>
            <p className="text-center text-md-left">  
              Integrate with third-party services and leverage ‘smart fields’ to reduce 
              time-per-application and make filling out forms a breeze.              
            </p>

          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <ProductBenefits id="personalised-cx" />
    </SectionPane>
  </MainLayout>
)

export default withFadeIn(PersonalisedCxPage)
