import React from "react"

import ContactContext from "../context"
import Formatic from "../../../components/vendor/Formatic"
import { Option, options } from "../data"

const option = options.find(({ id }) => id === Option.Support)

const SupportForm = ({ globalEmitter }) => (
  <ContactContext.Consumer>
    {({ forms: { "customer-support": form } }) => (
      <div className="contact-form--customer-support">
        <div className="text-center">
          <p>
            <img src={option.icon} alt={option.headline} />
          </p>
          <h2 className="text-primary">{option.headline}</h2>
          <p>{option.modalDescription}</p>
        </div>
        <Formatic
          globalEmitter={globalEmitter}
          formId={form.formId}
          theme="transformd-ds transformd-ds--primary"
        />
      </div>
    )}
  </ContactContext.Consumer>
)

export default SupportForm
