/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, image, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            defaultImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const ogImage = image || site.siteMetadata.defaultImage;
  const stringifiedKeywords =  keywords.length > 0  ? keywords.join(`, `) : "";


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta property="description" content={metaDescription}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:type" content={"website"}/>

      <meta property="og:image" content={ogImage}/>
      <meta property="og:image:width" content={1600}/>
      <meta property="og:image:height" content={920}/>


      <meta property="twitter:card" content={"summary"}/>
      <meta property="twitter:creator" content={site.siteMetadata.author}/>
      <meta property="twitter:title" content={title}/>
      <meta property="twitter:description" content={metaDescription}/>
      <meta property="twitter:image" content={ogImage}/>

      <meta name="keywords" content={stringifiedKeywords}/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  image: 'https://transformd.com/img/brand/logo-dark.png'
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default SEO
