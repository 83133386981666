import React from "react"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import { Link } from "gatsby"
import Learn from "../../components/header/Learn"

const TransformdStaticContent = () => (
  <Container className="header-container--content ">
    <Row>
      <Col md={{ span: 6 }} sm={{ span: 12 }}>
        <h2 className="wantop">
          Transform your digital experiences
        </h2>
        <p>
          Transformd's no-code platform makes it easy to collect and distribute data whilst effortlessly connecting customers, staff and systems. Easy to use drag and drop interface, fully brand customisable with seamless integrations.
        </p>
        <p><strong>A smarter, faster and easier way to deliver automated end-to-end processes.</strong></p>
        <Container className="h-200 header-items dt-1">
          <Learn className="" text="Request Demo" />
        </Container>
      </Col>
      <Col md={{ span: 6 }} sm={{ span: 12 }}>
        <Row className="d-flex align-items-stretch justify-content-end">
          <Col lg={{ span: 5 }} md={{ span: 6 }}>
            <Link to="/platform/personalised-cx" className="d-none d-md-inline">
              <Card className="header-items--card">
                <div className="header-items--card__img">
                  <Card.Img
                    variant="top"
                    src="https://transformd-cdn.s3-ap-southeast-2.amazonaws.com/img/product/1-%5Bd%5D-personalised-cx-%5Bthumb%5D.png"
                  />
                </div>
                <Card.Body className="d-flex flex-column align-items-around justify-content-between">
                  <Card.Title>PLATFORM</Card.Title>
                  <Card.Text>Personalised customer experiences</Card.Text>
                  <div className="d-md-flex justify-content-end">
                    <span className="header-items--card__icon" />
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col lg={{ span: 5 }} md={{ span: 6 }}>
            <Link to="/platform/data-analytics" className="d-none d-md-inline">
              <Card className="header-items--card">
                <div className="header-items--card__img">
                  <Card.Img
                    variant="top"
                    src="https://transformd-cdn.s3-ap-southeast-2.amazonaws.com/img/product/5-%5Bm%5D-data-%5Bthumb%5D.png"
                  />
                </div>
                <Card.Body className="d-flex flex-column align-items-around justify-content-between">
                  <Card.Title>PLATFORM</Card.Title>
                  <Card.Text>Data analytics & optimisation</Card.Text>
                  <div className="d-md-flex justify-content-end">
                    <span className="header-items--card__icon" />
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
)
export default TransformdStaticContent
