import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import ContactContext from "./context"
import ContactForm from "./components/ContactForm"
import ContactOptions from "./components/ContactOptions"
import { Option, options } from "./data"

const query = graphql`
  query FormsQuery {
    allFormsJson(filter: { category: { eq: "contact" } }) {
      edges {
        node {
          id
          formId
          category
        }
      }
    }
  }
`

class Contact extends Component {
  static getDerivedStateFromProps(props) {
    const forms = props.data.reduce(
      (accum, { node: { id, ...node } }) => ({ ...accum, [id]: node }),
      {}
    )

    return { forms }
  }

  constructor(props) {
    super(props)

    let activeOption = null

    const { initialModal } = props
    if (initialModal) {
      activeOption = initialModal
    }

    this.state = {
      activeOption,
    }
  }

  contextualValues = () => ({
    closeModal: this.closeModal,
    forms: this.state.forms,
    setActiveOption: this.setActiveOption,
  })

  setActiveOption = activeOption => this.setState({ activeOption })

  render() {
    const { activeOption } = this.state
    const { className, globalEmitter } = this.props

    return (
      <ContactContext.Provider value={this.contextualValues()}>
        <div className={classNames("contact", { [className]: className })}>
          <ContactForm
            globalEmitter={globalEmitter}
            option={activeOption}
            show={activeOption}
          />
          <ContactOptions options={options} />
        </div>
      </ContactContext.Provider>
    )
  }
}

Contact.defaultProps = {
  className: "",
  initialModal: null,
  globalEmitter: {},
}

Contact.propTypes = {
  className: PropTypes.string,
  initialModal: PropTypes.string,
  globalEmitter: PropTypes.object,
}

const withData = WrappedComponent => props => (
  <StaticQuery
    query={query}
    render={({ allFormsJson: { edges: data } }) => (
      <WrappedComponent {...props} data={data} />
    )}
  />
)

export { Option, options }

export default withData(Contact)
