import React from "react"
import { Container } from "react-bootstrap"

import MainLayout from "../../layout/main"
import SEO from "../../components/seo"
import SectionPane from "../../components/page/SectionPane"
import Title from "../../components/page/Title"
import Map from "../../components/contact/Map"
import Contact from "../../containers/contact"
import BlogContainer from "../../containers/blog"

const ContactPage = () => (
  <MainLayout className="page-blog">
    <SEO title="Blogs - Transformd" />
    <Title
      headline="Smarter, faster, easier digitisation."
    />

    <SectionPane className="pb-md-5" variant="light">
      <Container className="pb-md-5">
        <BlogContainer/>
      </Container>
    </SectionPane>
  </MainLayout>
)

export default ContactPage
