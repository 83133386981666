import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Button } from "react-bootstrap"
import classNames from "classnames"

import ContactContext from "../context"

const ContactOptions = ({ options }) => (
  <ContactContext.Consumer>
    {({ setActiveOption }) => (
      <Row className="contact-options">
        {options.map(({ headline, description, buttonText, icon, id }, i) => (
          <Col
            className={classNames(
              "contact-option text-center position-relative",
              {
                "mb-3 mb-md-0 pb-md-0 pb-5": i < options.length - 1,
                "pt-5 pt-md-0": i > 0,
              }
            )}
            md="4"
            key={i}
          >
            {icon && <img src={icon} alt={headline} className="contact-img" />}
            <h2 className="text-primary mt-5">{headline}</h2>
            <p>{description}</p>

            <Button className="mt-3" onClick={() => setActiveOption(id)}>
              {buttonText}
            </Button>
          </Col>
        ))}
      </Row>
    )}
  </ContactContext.Consumer>
)

ContactOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
      .isRequired,
    buttonText: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired)
}

export default ContactOptions
