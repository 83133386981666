import React, { Component, createRef } from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import BodyClassName from "react-body-classname"

import solutions from "../../data/solutions.json"

const MenuBodyOpenClass = "menu-open"
const MegaMenuSubWidth = 300
const MegaAnimationDelay = 300

const solutionUrls = solutions.map(({ id, title, navTitle, navDesc }) => ({
  key: id,
  text: navTitle || title,
  description: navDesc,
  url: `/solutions/${id}/`,
}))

// @todo data source
const menuItems = [
  {
    key: "platform",
    text: "Platform",
    items: [
      {
        key: "personalised-cx",
        text: "Personalised CX",
        description: "Tailored customer experience",
        url: "/platform/personalised-cx",
      },
      {
        key: "integrations",
        text: "Integrations",
        description: "Third party integrations ready to use",
        url: "/platform/integrations",
      },
      {
        key: "anyone-can-create",
        text: "Drag & drop",
        description: "Easy form creation",
        url: "/platform/anyone-can-create",
      },
      {
        key: "data-analytics",
        text: "Data analytics",
        description: "Analyse and optimise",
        url: "/platform/data-analytics",
      },
      {
        key: "beautiful",
        text: "Brand friendly",
        description: "Personalise your experience",
        url: "/platform/beautiful",
      },
      {
        key: "security",
        text: "Security",
        description: "Secure and private",
        url: "/platform/security",
      },
    ],
  },
  // {
  //   key: "benefits",
  //   text: "Benefits",
  //   url: "/benefits",
  // },
  {
    key: "solutions",
    text: "Solutions",
    url: "/solutions",
    items: solutionUrls,
  },
  {
    key: "case-studies",
    text: "Case studies",
    url: "/case-studies/tmbank",
  },
  {
    key: "blog",
    text: "Blog",
    url: "/blog",
  },
  {
    key: "contact",
    text: "Contact",
    url: "/contact",
  },
]

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.domRefs = {}
    menuItems.forEach(({ key }) => (this.domRefs[key] = createRef()))

    this.state = {
      activeMegaItem: null,
      className: "",
      heroMargins: {},
      megaItemTransitioning: false,
      menuFixed: false,
      open: false,
      openSubMenu: null,
    }
  }

  componentDidMount() {
    const menuFixed = window.scrollY > 0
    if (menuFixed !== this.state.menuFixed) {
      this.setState({ menuFixed })
    }

    window.addEventListener("scroll", this.onScroll)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activeMegaItem === null &&
      prevState.activeMegaItem !== null
    ) {
      // menu was hidden
      this.setState({ megaItemTransitioning: true })
    }

    if (this.state.megaItemTransitioning && !prevState.megaItemTransitioning) {
      setTimeout(
        () => this.setState({ megaItemTransitioning: false }),
        MegaAnimationDelay
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    const { menuFixed } = this.state
    const scrollY = window.scrollY || window.pageYOffset;

    if (scrollY > 0 && !menuFixed) {
      this.setState({ menuFixed: true })
    } else if (scrollY === 0 && menuFixed) {
      this.setState({ menuFixed: false })
    }
  }

  hideMega = () => this.setState({ activeMegaItem: null })

  handleMegaOnEnter = key => () => {
    const menuItem = menuItems.find(({ key: searchKey }) => searchKey === key)

    if (!menuItem.items) {
      // no sub-items to display
      this.hideMega()
      return
    }

    const domRef = this.domRefs[menuItem.key]

    // const marginLeft = MegaMenuSubWidth - domRef
    const marginLeft =
      domRef.current.offsetLeft -
      (MegaMenuSubWidth - domRef.current.offsetWidth) / 2

    this.setState(({ heroMargins }) => ({
      activeMegaItem: key,
      heroMargins: { ...heroMargins, [key]: marginLeft },
    }))
  }

  handleMobileOnClick = () =>
    this.setState(({ open }) => ({
      className: open ? "" : MenuBodyOpenClass,
      open: !open,
    }))

  handleMobileToggleSubMenuOnClick = key => () =>
    this.setState(({ openSubMenu }) => ({
      openSubMenu: openSubMenu === key ? null : key,
    }))

  renderDesktop() {
    const { activeMegaItem, heroMargins } = this.state

    return (
      <Nav className="d-none d-md-flex normal">
        {menuItems.map(({ key, text, url, items }) => (
          <div
            key={key}
            className={`text-center nav-item ${(items && activeMegaItem === key) && `nav-item--bold`}`}
            ref={this.domRefs[key]}
            onMouseEnter={this.handleMegaOnEnter(key)}
          >
            {url ? (
              <Link to={url} className="sub-title-sm nav-link">
                {text}
              </Link>
            ) : (
              <span className="sub-title-sm nav-link">{text}</span>
            )}

            {items && (
              <div
                className={`mega-menu box-shadow ${(activeMegaItem === key) && `mega-menu--active`}`}
                onMouseLeave={() => activeMegaItem === key && this.hideMega()}
              >
                <Nav
                  className="sub-menu"
                >
                  {items.map(({ key: subKey, text, description, url }) => (
                    <Nav.Item key={subKey} className="px-3">
                      <Link
                        to={url}
                        className="sub-title-sm nav-link ml-auto mr-auto"
                      >
                        {text}
                        <span className="submenu-description">{description}</span>
                      </Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            )}
          </div>
        ))}
      </Nav>
    )
  }

  renderMobile() {
    const { open, openSubMenu } = this.state

    return (
      <>
        <div
          className={`d-block d-md-none navigation-mobile-menu ${open && `navigation-mobile-menu--open`}`}
          onClick={this.handleMobileOnClick}
        />
        {open && (
          <div className="d-fixed d-md-none navigation-mobile-menu-display">
            <ul className="list-unstyled text-center pb-5 pb-md-0">
              {menuItems.map(({ items, key, text, url }) => (
                <li
                  key={key}
                  className={`${(openSubMenu === key) && `navigation-mobile-menu-parent--open`}`}
                >
                  {!items || !items.length === 0 ? (
                    <Link to={url} className="d-block">
                      {text}
                    </Link>
                  ) : (
                    <span
                      className="d-block has-child"
                      onClick={this.handleMobileToggleSubMenuOnClick(key)}
                    >
                      {text}
                    </span>
                  )}

                  {items && items.length > 0 && (
                    <ul
                      className={`list-unstyled navigation-mobile-menu-display--sub-menu ${(openSubMenu === key) && `navigation-mobile-menu-display--sub-menu-open`}`}
                    >
                      {items.map(
                        ({ key: subKey, text: subText, url: subUrl }) => (
                          <li key={subKey}>
                            <Link to={subUrl}>{subText}</Link>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }

  render() {
    const {
      activeMegaItem,
      className,
      megaItemTransitioning,
      menuFixed,
    } = this.state

    return (
      <BodyClassName
        className={`menu-fixed
        ${(activeMegaItem || megaItemTransitioning) && `menu-fixed--mega`}
        ${megaItemTransitioning && `menu-fixed--mega-animating`}
        `}

      >
        <>
          {this.renderDesktop()}
          {this.renderMobile()}
        </>
      </BodyClassName>
    )
  }
}

export default Navigation
