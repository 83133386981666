import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import MaterialIcon from "@material-ui/core/Icon"

import ProductWalkthroughContext from "./context"

const Skip = ({ icon, text }) => (
  <ProductWalkthroughContext.Consumer>
    {({ skip }) => (
      <div className="walkthrough-skip position-absolute text-center mr-auto ml-auto">
        <Button
          className="walkthrough-skip-button"
          variant="link"
          onClick={skip}
        >
          {text}
          {icon && (
            <MaterialIcon className="d-block ml-auto mr-auto">
              {icon}
            </MaterialIcon>
          )}
        </Button>
      </div>
    )}
  </ProductWalkthroughContext.Consumer>
)

Skip.defaultProps = {
  icon: "keyboard_arrow_down",
  text: "Skip Product",
}

Skip.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
}

export default Skip
