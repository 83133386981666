import React, { Component } from "react"
import { Container } from "react-bootstrap"

import Logo from "../transformd/Logo"
import Navigation from "./Navigation"
import Cta from "./Cta"

class Header extends Component {
  state = { heroItems: null }

  render() {
    return (
      <header className="header">
        <div className="header-bg" />
        <div className="header-box-shadow" />

        <Container className="d-flex h-100 header-items align-items-center">
          <Logo />
          <Navigation />
          <Cta text="Request Demo" />
        </Container>
      </header>
    )
  }
}

export default Header
