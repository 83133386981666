import React from "react"

import ContactContext from "../context"
import Formatic from "../../../components/vendor/Formatic"
import { Option, options } from "../data"

const option = options.find(({ id }) => id === Option.Demo)

const DemoForm = ({ globalEmitter }) => {
  return (
    <ContactContext.Consumer>
      {({ forms: { "request-demo": form } }) => (
        <div className="contact-form--demo">
          <div className="text-center">
            <p>
              <img src={option.icon} alt={option.headline} />
            </p>
            <h2 className="text-primary">{option.headline}</h2>
            <p>{option.modalDescription}</p>
          </div>
          <Formatic
            formId={form.formId}
            theme="transformd-ds transformd-ds--primary"
            globalEmitter={globalEmitter}
          />
        </div>
      )}
    </ContactContext.Consumer>
  )
}

export default DemoForm
