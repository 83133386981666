import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import Html from "../components/html"
import SectionPane from "../components/page/SectionPane"
import Solutions from "../components/solutions/Preview"

const SolutionTemplate = ({
  data: {
    solutionsJson: { title, description, pageTitle, id },
  },
}) => (
  <MainLayout>
    <SEO title={pageTitle} />
    <Title headline={<Html body={title} />} subTitle="Solutions" />

    <SectionPane size="sm" className="box-shadow">
      <Container>
        <Row>
          <Col md="12" className="text-center pb-5">
            <Html body={description} />
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane size="sm" variant="light" className="box-shadow">
      <Container className="pt-4 pb-0 pb-md-5">
        <Solutions className="pb-4 pb-md-5 pt-5" exclude={[id]} />
      </Container>
    </SectionPane>
  </MainLayout>
)

export const query = graphql`
  query($slug: String!) {
    solutionsJson(id: { eq: $slug }) {
      id
      title
      pageTitle
      description
    }
  }
`

export default SolutionTemplate
