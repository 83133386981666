import React, { Component } from "react"
import classNames from "classnames"

const Delay = 100

const withFadeIn = WrappedComponent => {
  class WithFadeIn extends Component {
    state = { ready: false }

    componentDidMount() {
      setTimeout(() => this.setState({ ready: true }), Delay)
    }

    render() {
      const { ready } = this.state

      return (
        <div
          className={classNames("page-wrapper-fade", {
            "page-wrapper-fade--ready": ready,
          })}
        >
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }

  return WithFadeIn
}

export default withFadeIn
