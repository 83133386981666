import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "gatsby"

import Html from "../../html"

const Solution = ({ id, image, shortDesc, title }) => (
  <Card className="solution mb-5 mb-md-0">
    <Card.Body className="text-center">
      {image && (
        <p>
          <img src={image} alt={title} className="img img-solution-icon" />
        </p>
      )}

      <h2 className="text-dark mb-3 md-h3">
        <Html body={title} />
      </h2>
      <p>{shortDesc}</p>
      <p>
        <Link to={`/solutions/${id}`} className="btn btn-light mb-4 mt-2">
          Find out more
        </Link>
      </p>
    </Card.Body>
  </Card>
)

Solution.propTypes = {
  id: PropTypes.string.isRequired,
  shortDesc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Solution
