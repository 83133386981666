import React from "react"
import PropTypes from "prop-types"
import FormaticSdk, { Data } from "@transformd-ltd/sdk"
import globalEmitter from "./GlobalEmitter"

const productionProps = {
  apiKey: "1724193883960bf39ab4A8703c02e598311DFc14454523b3f4A73d04CE05f9Fd",
  apiServerUrl: "https://api.transformd.com",
  serverUrl: "https://api.transformd.com/graphql",
  subscriptionServerUrl: "wss://api.transformd.com/subscriptions",
}

let data

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const Formatic = ({
  channel,
  config,
  environment,
  formId,
  theme,
}) => {

  data = Data ? new Data() : null
  
  React.useEffect(() => {
    if (!isBrowser) {
      return;
    } 

    if (data) {
      const emitter = data.getEmitter()
      emitter.on("SessionStarted", data => {
        globalEmitter.emit(
          "GlobalSessionStarted",
          Object.assign(data, ...{ formId: formId })
        )
      })
      emitter.on("SessionComplete", data => {
        globalEmitter.emit(
          "GlobalSessionCompleted",
          Object.assign(data, ...{ formId: formId })
        )
      })
    }
  }, [])

  if (!isBrowser) {
    return <div>{'Please render in browser'}</div>
  } 

  return (
    <FormaticSdk
      data={data}
      {...productionProps}
      channel={channel}
      config={config}
      environment={environment}
      formId={formId}
      theme={theme}
    />
  )
}

Formatic.defaultProps = {
  channel: "master",
  config: "default",
  environment: "Live",
  theme: "transformd",
  globalEmitter: {},
}

Formatic.propTypes = {
  channel: PropTypes.string,
  config: PropTypes.string,
  environment: PropTypes.string,
  formId: PropTypes.number.isRequired,
  theme: PropTypes.string,
  globalEmitter: PropTypes.object,
}

export default Formatic
