import React from "react"
import PropTypes from "prop-types"

const Title = ({ text }) => (
  <div className="walkthrough-title text-center position-absolute w-100 mt-5">
    <span className="h3 d-block pt-5">{text}</span>
  </div>
)

Title.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Title
