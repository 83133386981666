import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row } from "react-bootstrap"
// import { Input } from "@transformd/design-system-components"
import Input  from "./Input";
import fuzzy from "fuzzy"

import Integration from "./Integration"


class Integrations extends Component {
  static getDerivedStateFromProps({ data }) {
    const integrations = data.reduce(
      (keyedObj, { node }) => ({
        ...keyedObj,
        [node.name.toLowerCase()]: node,
      }),
      {}
    )

    return { integrations }
  }

  constructor(props) {
    super(props)

    this.state = {
      searchValue: "",
    }
  }

  handleSearchOnChange = ({ target: { value: searchValue } }) =>
    this.setState({ searchValue })

  searchResults = () => {
    const { data } = this.props
    const { integrations, searchValue } = this.state

    return fuzzy
      .filter(
        searchValue.toLowerCase(),
        data.map(({ node: { name } }) => name.toLowerCase())
      )
      .sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA)
      .map(({ string }) => integrations[string])
  }

  render() {
    const { searchValue } = this.state
    const { noResultsText } = this.props

    const searchResults = this.searchResults()

    return (
      <div className="integrations-container">
        <div className="integrations-search mb-5">
          <Input
            label="Search integrations"
            addon={{ type: "append", icon: "search" }}
            onChange={this.handleSearchOnChange}
            value={searchValue}
          />
        </div>

        {searchResults.length > 0 ? (
          <Row className="integrations">
            {searchResults.map(node => (
              <Integration key={node.id} nodeKey={node.key} {...node} />
            ))}
          </Row>
        ) : (
          <em>{noResultsText}</em>
        )}
      </div>
    )
  }
}

Integrations.defaultProps = {
  data: {},
  noResultsText: "No integrations found.",
}

Integrations.propTypes = {
  data: PropTypes.array,
  noResultsText: PropTypes.string,
}

export default Integrations
