import React from 'react';
import Filter from '../../components/Article/Filter';
import Search from '../../components/Article/Search';

const BlogHeading = () => (
  <>
    <Filter/>
    <Search/>
  </>
)

export default BlogHeading
