// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
export const viewport = {
  xs: 0,
  sm: 576,
  md: 766,
  lg: 1200,
  xl: 1420,
}

// Header height
//
// Height of the fixed header at the top of the page
export const HeaderHeight = 70
