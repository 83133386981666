import React, { Component } from "react"
import PropTypes from "prop-types"
import createDOMPurify from "dompurify"

class Html extends Component {
  state = { safe: "" }

  componentDidMount() {
    const { body } = this.props
    const DOMPurify = createDOMPurify(window)
    const safe = DOMPurify.sanitize(body)

    this.setState({ safe })
  }

  render() {
    const { safe } = this.state

    return <div dangerouslySetInnerHTML={{ __html: safe }} />
  }
}

Html.propTypes = {
  body: PropTypes.string.isRequired,
}

export default Html
