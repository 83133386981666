import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"
import classNames from "classnames"

// @todo hook up to data source
const assets = {
  transformations: [
    {
      key: "TMBank",
      img: "/img/transformations/tmb.png",
      className: "transformation--tmb",
    },
    {
      key: "Interrisk",
      img: "/img/transformations/interisk.svg",
      url: "http://www.interrisk.com.au",
    },
    {
      key: "Containgo",
      img: "/img/transformations/contango.png",
      url: "https://contango.com.au",
    },
    {
      key: "Firefighter",
      img: "/img/transformations/firefighter.png",
      url: "https://contango.com.au",
    },
    {
      key: "Gotafe",
      img: "/img/transformations/gotafe.png",
      url: "https://contango.com.au",
    },
    {
      key: "HPB",
      img: "/img/transformations/hpb.png",
      url: "https://contango.com.au",
    },
    {
      key: "OneReg",
      img: "/img/transformations/one_registry.png",
      url: "https://contango.com.au",
    },
    {
      key: "policebank",
      img: "/img/transformations/police_bank.png",
      url: "https://contango.com.au",
    },
    {
      key: "unibank",
      img: "/img/transformations/unibank.png",
      url: "https://contango.com.au",
    },
    {
      key: "BankVic",
      img: "/img/transformations/bankvic.svg",
      url: "https://www.bankvic.com.au/",
    },
    {
      key: "TennisAustralia",
      img: "/img/transformations/tennis.svg",
      url: "https://www.tennis.com.au/",
    },
    {
      key: "Cuscal",
      img: "/img/transformations/cuscal.png",
      url: "https://www.cuscalpayments.com.au/",
    },
    {
      key: "Fujiflim",
      img: "/img/transformations/fujiflim.svg",
      url: "https://www.fujifilm.com/",
    },
  ],
  partners: [
    {
      key: "technology partners AWS",
      img: "/img/technologyPartners/aws.png",
      className: "transformation--technology",
    },
    {
      key: "technology partners E",
      img: "/img/technologyPartners/e.png",
      className: "transformation--technology",
    },
    {
      key: "technology partners equifax",
      img: "/img/technologyPartners/equifax.png",
      className: "transformation--technology",
    },
    {
      key: "technology partners greenid",
      img: "/img/technologyPartners/greenid.png",
      className: "transformation--technology",
    },
    {
      key: "technology partners sitecore",
      img: "/img/technologyPartners/sitecore.png",
      className: "transformation--technology",
    },
    {
      key: "docusign",
      img: "/img/technologyPartners/docusign.png",
      title: "Docusign",
      url: "https://www.docusign.com.au",
    },
    {
      key: "airdocs",
      img: "/img/technologyPartners/airdocs.svg",
      url: "https://www.airdocs.io/",
    },
    {
      key: "formsagency",
      img: "/img/technologyPartners/formsagency.png",
      url: "https://www.theformsagency.com/",
    },
    {
      key: "onespan",
      img: "/img/technologyPartners/onespan.svg",
      url: "https://www.onespan.com/",
    },
    {
      key: "mambu",
      img: "/img/technologyPartners/mambu.png",
      title: "Docusign",
      url: "https://www.mambu.com",
    },
  ],
}

const Transformations = ({ title, asset }) => (
  <div className="footer-transformations d-flex flex-column align-items-center justify-content-center pr-4 pl-4">
    {title && <h4>{title}</h4>}
    <div className="footer-transformations-container col-md-8 d-flex flex-wrap justify-content-center">
      {assets[asset].map(({ key, img, className = "" }) => (
        <div
          className={classNames("footer-transformations-transformation", {
            [className]: className,
          })}
          key={key}
        >
          <img src={withPrefix(img)} alt={key} />
        </div>
      ))}
    </div>
  </div>
)

Transformations.defaultProps = {
  title: "Our transformations:",
  asset: "transformations",
}

Transformations.propTypes = {
  title: PropTypes.string,
  asset: PropTypes.string,
}

export default Transformations
