import React, { memo } from "react"

import FooterCopyright from "./FooterCopyright"
import FooterLinks from "./FooterLinks"
import Transformations from "./Transformations"

const Footer = () => (
  <footer className="footer">
    <Transformations title="Our Transformations" />
    <Transformations
      title="Our Technology, data and integration partners"
      asset="partners"
    />
    <FooterLinks />
    <FooterCopyright />
  </footer>
)

export default memo(Footer)
