import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "gatsby"

const Benefit = ({ headline, img, shortDescription, url }) => (
  <Link to={url} className="container-link">
    <Card className="product-benefit zoom-container">
      <Card.Header className="p-0 zoom border-0">
        <img
          src={img}
          alt={headline}
          className="card-img-top ml-0 mt-0 mr-0 mb-0"
        />
      </Card.Header>
      <Card.Body>
        <h3 className="text-base-dark-50w">{headline}</h3>
        <p>{shortDescription}</p>
      </Card.Body>
    </Card>
  </Link>
)

Benefit.propTypes = {
  headline: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default Benefit
