import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const SectionPane = ({ children, className, size, variant }) => (
  <div
    className={classNames(
      `section-pane section-pane--${variant} section-pane--${size}`,
      { [className]: className }
    )}
  >
    {children}
  </div>
)

SectionPane.defaultProps = {
  className: "",
  size: "md",
  variant: "white",
}

SectionPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "xlg","xxlg"]),
  variant: PropTypes.oneOf([
    "dark",
    "white",
    "light",
    "primary",
    "orange",
    "red",
    "dark2",
    "green",
    "medium-grey",
    "primaryStatic",
  ]),
}

export default SectionPane
