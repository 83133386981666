import React from 'react';
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import BlogArticle from './BlogArticle';
import BlogHeading from './BlogHeading';

const query = graphql`
  query  {
        allNodeArticle {
          totalCount,
          nodes {
            revision_timestamp
            title,
            field_slug,
            body {
              format
              processed
              summary
            },
            field_image {
              width
              height
              alt
            }
            relationships {
              field_tags {
                name
              }
              field_image {
                uri {
                  url
                } 
              }
            }
          }
      }      
  }
`

class Blog extends React.Component
{
    render()
    {
        const {data} = this.props;
        return <div className="blog-page">
            <BlogArticle data={data}/>
        </div>
    }
}

const withData = WrappedComponent => props => (
    <StaticQuery
      query={query}
      render={ ({allNodeArticle: {nodes} }) => (
        <WrappedComponent {...props} data={nodes} />
      )}
    />
)

export default withData(Blog);