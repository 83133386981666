import React, { createRef, Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import BodyClassName from "react-body-classname"
import { animateScroll } from "react-scroll"
import { debounce } from "lodash"
import mouseWheel from "mouse-wheel"
import ReactSwipeEvents from "react-swipe-events"

import Controls from "./walkthrough/Controls"
import ProductWalkthroughContext from "./walkthrough/context"
import Skip from "./walkthrough/Skip"
import Slide from "./walkthrough/Slide"
import { HeaderHeight } from "../../../config/responsive"
import TransformdContext from "../../../themes/transformd/context"

const Direction = {
  Down: "ArrowDown",
  Up: "ArrowUp",
}

const SlideDirection = {
  Next: "next",
  Prev: "prev",
}

const SlideDuration = 1000
const DebounceDelay = 500
const DebounceWheelDelay = 50
const ExitInterval = 500

class ProductWalkthrough extends Component {
  state = {}
  constructor(props) {
    super(props)

    const { slides } = props

    const currentSlide = slides.length > 0 ? slides[0].key : null
    const currentSlideIndex = currentSlide
      ? slides.map(({ key }) => key).indexOf(currentSlide)
      : 0

    this.state = {
      currentSlide,
      currentSlideIndex,
      exiting: false,
      initialized: false,
      firstRender: true,
      fullScreen: false,
      hijack: true,
      slideDirection: SlideDirection.Next,
      sliding: false,
      windowHeight: 0,
    }

    this.containerRef = createRef()
    this.rendered = [currentSlide]

    this.debouncedChangeSlide = debounce(this.changeSlide, DebounceDelay, {
      leading: true,
      trailing: false,
    })

    this.debouncedHandleOnWheel = debounce(
      this.handleOnWheel,
      DebounceWheelDelay,
      {
        leading: true,
        trailing: false,
      }
    )
  }

  componentDidMount() {
    const { innerHeight } = window
    this.setState({
      windowHeight: innerHeight,
    })

    // 'catches' the user and puts them into fullscreen mode
    // window.removeEventListener("wheel", this.wheelEventListener)
    // window.addEventListener("wheel", this.debouncedHandleOnWheel)
    window.addEventListener("scroll", this.handleOnScroll)
    window.addEventListener("resize", this.handleOnResize)
    window.addEventListener("ontouchmove", this.handleOnResize)
    window.addEventListener("ontouchend", this.handleOnResize)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.fullScreen && this.state.fullScreen) {
      this.setState({ initialized: true })

      setTimeout(() => {
        this.smoothScroll(this.containerRef.current.offsetTop)

        this.wheelEventListener = mouseWheel(
          window,
          this.debouncedHandleOnWheel,
          false
        )
        document.addEventListener("keydown", this.handleOnKeyDown)
        window.addEventListener("wheel", this.debouncedHandleOnWheel)
      }, 50)
    } else if (prevState.fullScreen && !this.state.fullScreen) {
      // out of fullscreen mode
      document.removeEventListener("keydown", this.handleOnKeyDown)
      window.removeEventListener("wheel", this.wheelEventListener)
    } else if (!prevState.sliding && this.state.sliding) {
      setTimeout(() => this.setState({ sliding: false }), 1200)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll)
    window.removeEventListener("resize", this.handleOnResize)
    window.removeEventListener("ontouchmove", this.handleOnResize)
    window.removeEventListener("ontouchend", this.handleOnResize)
  }

  smoothScroll = to => {
    const { browser } = this.context
    if (["ie", "edge"].includes(browser)) {
      window.scrollTo(0, to)
    } else {
      window.scroll({
        top: to,
        behavior: "smooth",
      })
    }
  }

  handleOnResize = () => {
    const { innerHeight } = window
    if (innerHeight !== this.state.windowHeight) {
      this.setState({
        windowHeight: innerHeight,
      })
    }

    const { fullScreen } = this.state
    const { mobile } = this.context
    if (fullScreen && mobile) {
      // check for a divergence between the window offsetTop and position of the slider
      // on mobile, with the appearing/disappearing URL bar, sometimes a discrepancy occurs
      // pushing these out of sync
      const { offsetTop } = this.containerRef.current
      if (window.pageYOffset !== offsetTop) {
        window.scroll({ top: offsetTop })
      }
    }
  }

  handleOnSwipe = direction => {
    this.handleOnResize()

    const { fullScreen } = this.state
    if (!fullScreen) {
      return
    }

    this.handleOnKeyDown({ key: direction })
    setTimeout(this.handleOnResize, 1000)
  }

  handleOnKeyDown = ({ key }) => {
    if (key === Direction.Down || key === Direction.Up) {
      this.debouncedChangeSlide(key)
    }
  }

  handleOnScroll = () => {
    this.handleOnResize()
    setTimeout(this.handleOnResize, 1000)

    const { currentSlideIndex, fullScreen, hijack, initialized } = this.state
    const { slides } = this.props
    const scrollY = window.scrollY || window.pageYOffset

    // todo responsiveness
    const { offsetTop } = this.containerRef.current
    if (!initialized && hijack) {
      if (
        ((scrollY >= offsetTop && currentSlideIndex === 0) ||
          (scrollY <= offsetTop && currentSlideIndex === slides.length - 1)) &&
        !fullScreen
      ) {
        this.setState({ fullScreen: true })
      }
    }

    const { windowHeight } = this.state
    if (initialized && scrollY < offsetTop - windowHeight) {
      this.reset()
    }
  }

  handleOnWheel = (x, y) => {
    if (y === 0) {
      return
    }

    const { sliding } = this.state
    if (!sliding) {
      const direction = y < 0 ? Direction.Up : Direction.Down

      this.changeSlide(direction)
    }
  }

  setActiveSlide = slide => {
    const { slides } = this.props
    const currentSlideIndex = slides.map(({ key }) => key).indexOf(slide)

    this.setState({
      currentSlideIndex,
      currentSlide: slide,
      sliding: true,
    })
  }

  changeSlide = direction => {
    const { currentSlideIndex } = this.state
    const { slides } = this.props

    const newSlideIndex =
      direction === Direction.Up ? currentSlideIndex - 1 : currentSlideIndex + 1

    if (newSlideIndex >= 0 && newSlideIndex < slides.length) {
      const slideDirection =
        newSlideIndex > currentSlideIndex
          ? SlideDirection.Next
          : SlideDirection.Prev

      const currentSlide = slides[newSlideIndex].key
      let firstRender = false
      if (!this.rendered.includes(currentSlide)) {
        this.rendered = [...this.rendered, currentSlide]
        firstRender = true
      }

      this.setState({
        currentSlideIndex: newSlideIndex,
        currentSlide,
        firstRender,
        slideDirection,
        sliding: true,
      })
    } else {
      // leave product walkthrough
      this.exitFullScreen(null, newSlideIndex > 0)
    }
  }

  reset = () => {
    const { slides } = this.props

    this.setState({
      currentSlideIndex: 0,
      currentSlide: slides[0].key,
      initialized: false,
      sliding: false,
    })
  }

  skip = () => {
    const { slides } = this.props
    const { windowHeight } = this.state

    this.setState({ hijack: false }, () => {
      this.exitFullScreen(() => {
        animateScroll.scrollTo(
          this.containerRef.current.offsetTop + windowHeight - HeaderHeight,
          { duration: SlideDuration }
        )

        setTimeout(() => {
          this.setState({
            currentSlideIndex: slides.length - 1,
            currentSlide: slides[slides.length - 1].key,
            hijack: true,
            sliding: true,
          })
        }, SlideDuration)
      })
    })
  }

  exitFullScreen = (callback, scrollToEnd = true) => {
    this.setState({ exiting: true }, () => {
      const { offsetTop } = this.containerRef.current
      const { windowHeight } = this.state

      if (scrollToEnd) {
        setTimeout(
          () => animateScroll.scrollTo(offsetTop + windowHeight - HeaderHeight),
          100
        )
      } else {
        setTimeout(() => animateScroll.scrollTo(offsetTop - windowHeight), 100)
      }

      setTimeout(() => {
        window.removeEventListener("wheel", this.debouncedHandleOnWheel)
        document.removeEventListener("keydown", this.handleOnKeyDown)

        if (typeof callback === "function") {
          this.setState({ exiting: false, fullScreen: false }, callback)
        } else {
          this.setState({ exiting: false, fullScreen: false })
        }
      }, ExitInterval)
    })
  }

  contextualValues = () => ({
    setActiveSlide: this.setActiveSlide,
    skip: this.skip,
    windowHeight: this.state.windowHeight,
  })

  render() {
    const { slides } = this.props
    const {
      currentSlide,
      currentSlideIndex,
      exiting,
      firstRender,
      fullScreen,
      initialized,
      slideDirection,
      windowHeight,
    } = this.state

    return (
      <ProductWalkthroughContext.Provider value={this.contextualValues()}>
        <BodyClassName className={classNames({ fullscreen: fullScreen })}>
          <div
            className={classNames("walkthrough", {
              [`walkthrough-slide--${currentSlide}`]: currentSlide,
              [`walkthrough-variant--${
                slides.find(({ key }) => key === currentSlide).variant
              }`]: currentSlide,
              "walkthrough--active": fullScreen,
              "walkthrough--complete": initialized && !fullScreen,
              "walkthrough--exiting": exiting,
            })}
            ref={this.containerRef}
            style={{ height: windowHeight }}
          >
            <ReactSwipeEvents
              onSwipedUp={() => this.handleOnSwipe(Direction.Down)}
              onSwipedDown={() => this.handleOnSwipe(Direction.Up)}
            >
              <>
                <div
                  className="walkthrough-slides"
                  style={{
                    marginTop: `-${currentSlideIndex * windowHeight}px`,
                  }}
                >
                  {slides.map(
                    ({ headline, body, img, mobileImg, key, url, variant }) => (
                      <Slide
                        key={key}
                        currentSlide={currentSlide}
                        direction={slideDirection}
                        firstRender={currentSlide === key && firstRender}
                        headline={headline}
                        body={body}
                        img={img}
                        mobileImg={mobileImg}
                        slideKey={key}
                        url={url}
                        variant={variant}
                      />
                    )
                  )}
                </div>

                <Controls currentSlide={currentSlide} slides={slides} />
                <Skip />
              </>
            </ReactSwipeEvents>
          </div>
        </BodyClassName>
      </ProductWalkthroughContext.Provider>
    )
  }
}

ProductWalkthrough.contextType = TransformdContext

ProductWalkthrough.defaultProps = {
  title: "How do we do this?",
}

ProductWalkthrough.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      img: PropTypes.string.isRequired,
      mobileImg: PropTypes.string,
      variant: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default ProductWalkthrough
