import React from 'react';
import ArticlePreview from '../../components/Article/ArticlePreview';

class BlogArticle extends React.Component {
    parseDate = (str) => {
        const dateStr = new Date(str);
        const formattedDate = [
            dateStr.getDate(),
            dateStr.getMonth(),
            dateStr.getFullYear()
        ]
        const daysEscaped = new Date(new Date() - dateStr).getDate();
        return { 
            daysEscaped,
            formatted: formattedDate.join('/')
        };
    }

    render()
    {
        const {data} = this.props;
        return <div className="blog-article__container">
            <div className="container">
                <div className="row">
                    {
                        data.map( articleData => {
                            const revisionData = this.parseDate(articleData.revision_timestamp);
                            return <ArticlePreview key={articleData.field_slug} {...articleData} revisionData={revisionData}/>
                        })
                    }
                </div>
            </div>
        </div>
    }
}

export default BlogArticle;