import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

const Cta = ({ text }) => (
  <Link to="/contact/get-started" className="d-none d-md-inline">
    <Button className="cta" variant="primary">
      {text}
    </Button>
  </Link>
)

Cta.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Cta
