// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-case-studies-tmbank-js": () => import("./../../../src/pages/case-studies/tmbank.js" /* webpackChunkName: "component---src-pages-case-studies-tmbank-js" */),
  "component---src-pages-contact-get-started-js": () => import("./../../../src/pages/contact/get-started.js" /* webpackChunkName: "component---src-pages-contact-get-started-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-anyone-can-create-js": () => import("./../../../src/pages/platform/anyone-can-create.js" /* webpackChunkName: "component---src-pages-platform-anyone-can-create-js" */),
  "component---src-pages-platform-beautiful-js": () => import("./../../../src/pages/platform/beautiful.js" /* webpackChunkName: "component---src-pages-platform-beautiful-js" */),
  "component---src-pages-platform-data-analytics-js": () => import("./../../../src/pages/platform/data-analytics.js" /* webpackChunkName: "component---src-pages-platform-data-analytics-js" */),
  "component---src-pages-platform-integrations-js": () => import("./../../../src/pages/platform/integrations.js" /* webpackChunkName: "component---src-pages-platform-integrations-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-platform-personalised-cx-js": () => import("./../../../src/pages/platform/personalised-cx.js" /* webpackChunkName: "component---src-pages-platform-personalised-cx-js" */),
  "component---src-pages-platform-security-js": () => import("./../../../src/pages/platform/security.js" /* webpackChunkName: "component---src-pages-platform-security-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-of-business-js": () => import("./../../../src/pages/terms-of-business.js" /* webpackChunkName: "component---src-pages-terms-of-business-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

