import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input as ReactInput } from "reactstrap";

class Input extends Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = { empty: !value || value.length === 0, value };
  }

  onChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.setState({ empty: value.length === 0, value });

    onChange(e);
  };

  render() {
    const { empty, value } = this.state;
    const { className } = this.props;

    return (
      <ReactInput
        {...this.props}
        className={`${!empty ? "has-value" : ""} ${className}`}
        value={value}
      />
    );
  }
}

Input.defaultProps = {
  className: "",
  value: ""
};

Input.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
};

export default Input;
