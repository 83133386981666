import React from "react"

export const Option = {
  Demo: "request-demo",
  Sales: "sales-enquiry",
  Support: "customer-support",
}

// @todo data souce
export const options = [
  {
    id: Option.Demo,
    headline: "Request a Demo",
    icon:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/request-a-demo.png",
    description: (
      <>
        Request a demonstration of
        <br />
        Tranformd’s powerful platform
        <br />
        and see for yourself.
      </>
    ),
    modalDescription:
      "Discover firsthand how the Transformd platform can integrate with your business systems to transform your tasks, and manual processes into a fully branded digital experience.",
    buttonText: "Request a Demo",
  },
  {
    id: Option.Sales,
    headline: "Sales enquiry",
    icon:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/sales-enquiry.png",
    description: (
      <>
        Reach out to our sales team
        <br />
        directly for assistance with all
        <br />
        sales related inquiries.
      </>
    ),
    modalDescription:
      "Reach out to our sales team directly for assistance with all sales related inquiries.",
    buttonText: "Sales Enquiry",
  },
  {
    id: Option.Support,
    headline: "Customer Support",
    icon:
      "https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/customer-support.png",
    modalDescription:
      "Get in touch with customer support and let us help you get back on your feet.",
    description: (
      <>
        Get in touch with customer
        <br />
        support and let us help you get
        <br />
        back on your feet.
      </>
    ),
    buttonText: "Customer Support",
  },
]
