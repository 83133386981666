import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import SectionPane from "../components/page/SectionPane"
import TimeTransformd from "../components/contact/Transformd"

const BenefitsPage = () => (
  <MainLayout>
    <SEO title="Organisation Benefits" />
    <Title
      headline={
        <>
          A digital platform with
          <br />
          tangible benefits
        </>
      }
      subTitle="Organisation benefits"
      variant="primary"
    />

    <SectionPane>
      <Container>
        <h2 className="h2 md-h1 text-center mb-5 anchor" id="improved-outcomes">
          Improved business outcomes
        </h2>
        <p className="text-center">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/web/benefits/large-placeholder.png"
            alt="Large placeholder"
            className="mt-4 mb-5 benefit-img-hero missing d-none"
          />
        </p>

        <Row>
          <Col className="mb-md-5 mb-4" md={{ span: 5, offset: 1 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/convert-more-customers.png"
                alt="Convert more customers"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Convert more customers</h4>
            <p className="text-center">
              Increase your onboarding conversion rates by analysing behavioural
              data. See where your customers are abandoning the experience,
              hesitating and where they are making errors. Make informed
              enhancements that positively impact your conversions and create a
              competitive advantage.
            </p>
          </Col>
          <Col className="mb-md-5 mb-4" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/faster-time-to-value.png"
                alt="Faster time to value"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Faster time to value</h4>
            <p className="text-center">
              Our automated workflows can help your customers move faster
              through an online experience, with less time required for data
              collection and processing. Time to value has been proven to
              increase customer satisfaction which means greater customer
              loyalty and longevity.
            </p>
          </Col>
          <Col className="mb-4 mb-md-2 mt-md-5" md={{ offset: 1, span: 5 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/increased-cross-sell.png"
                alt="Increased cross sell"
                className="benefit-img missing"
              />
            </p>
            <h4 className="text-center">Increased cross sell</h4>
            <p className="text-center">
              Sell more to your customers giving them experiences driven from
              their perspective, allowing them to buy the combination of
              products they need in a single sitting. Using transformd,
              organisations can offer multiple product and service offerings
              seamlessly through a single customer journey.
            </p>
          </Col>
          <Col className="mb-md-2 mt-md-5" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/happier-customer-and-staff.png"
                alt="Happier customers and staff"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Happier customers and staff</h4>
            <p className="text-center">
              Dynamically personalise your customer experiences by automating
              repetitive tasks and pre-populating known information. See your
              NPS improve as you allow your customers to seamlessly move through
              tasks without losing time due to irrelevant information. Feel the
              benefit of automations internally too, by freeing up staff to
              focus on creating incremental value for the organisation.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane variant="light">
      <Container>
        <h2
          className="h2 md-h1 text-center mb-5 anchor"
          id="operational-efficiency"
        >
          Operational efficiency
        </h2>
        <p className="text-center">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/Operational-Efficiency.gif"
            alt="Operational efficiency"
            className="mt-4 mb-5 benefit-img-hero d-none"
          />
        </p>

        <Row>
          <Col className="mb-0" md={{ span: 5, offset: 1 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/reduce-operating-costs.png"
                alt="Reduce operating costs"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Reduce operating costs</h4>
            <p className="text-center">
              Eliminate the repetition of manual processes by introducing
              sophisticated digitisation and automation. Reap the benefits with
              significantly reduced operating costs and improved bottom line
              performance.
            </p>
          </Col>
          <Col className="mb-0" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/improve-data-quality.png"
                alt="Improve data quality"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Improve data quality</h4>
            <p className="text-center">
              Reduce the impact of errors through manual user input by
              validating and verifying data in real-time. Improve the accuracy
              of your data, simplify downstream automations, integrations and
              business intelligence initiatives.
            </p>
          </Col>
          <Col className="mb-4 mb-md-2 mt-md-5" md={{ offset: 1, span: 5 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/configuration-over-code.png"
                alt="Configuration over code"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Configuration over code</h4>
            <p className="text-center">
              Enable configuration over code and watch your business achieve
              more, at a reduced cost. Better utilise your technical staff and
              IT departments by removing the dependence and empowering others to
              deliver customer value.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane>
      <Container>
        <h2 className="h2 md-h1 text-center mb-5 anchor" id="business-agility">
          Business agility
        </h2>
        <p className="text-center">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/business-agility.png"
            alt="Business agility"
            className="mt-4 mb-5 benefit-img-hero oops d-none"
          />
        </p>

        <Row>
          <Col className="mb-md-5 mb-4" md={{ span: 5, offset: 1 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/traditional-vs-transformd.png"
                alt="Traditional vs. Transformd"
                className="benefit-img missing"
              />
            </p>
            <h4 className="text-center">Traditional vs. Transformd</h4>
            <p className="text-center">
              Traditional digital transformation begins within an organisation
              and works its way out toward the customer experience layer – a
              layer that is often an afterthought/ locked to the software
              development life cycle. The Transformd Platform helps your
              business flip this problem on its head by enabling a customer
              first approach to digital transformation and business agility.
            </p>
          </Col>
          <Col className="mb-md-5 mb-4" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/transformational-scale.png"
                alt="Transformational scale"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Transformational scale</h4>
            <p className="text-center">
              The Transformd Platform embraces configuration over code so that
              the power to redefine your business sits in the hands of those who
              know the customers best. Specifically built to enable
              non-technical users, experiences can be optimised faster and
              project costs can be kept low.
            </p>
          </Col>
          <Col className="mb-md-2 mt-md-5 mb-4" md={{ offset: 1, span: 5 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/weeks-not-months.png"
                alt="Weeks not months and years"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Weeks not months and years</h4>
            <p className="text-center">
              Don’t let speed to market impact your competitive advantage. It
              doesn’t have to take years, or months to deliver dramatic
              improvements. The Transformd Platform enables organisations to
              deliver higher quality experiences in a fraction of the time
              thanks to the ease of building and delivering experiences that
              automatically connect up to your existing cloud infrastructure
              through a simple, configuration-driven interface.
            </p>
          </Col>
          <Col className="mb-md-2 mt-md-5" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/continuous-improvement.png"
                alt="Continuous improvement"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Continuous improvement</h4>
            <p className="text-center">
              Our customers are always changing, so we have built a platform
              that enables you to move at pace with them. Optimise your
              customers journey in minutes with informed, data driven insights
              and see your customers satisfaction and conversions increase.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane variant="light">
      <Container>
        <h2 className="h2 md-h1 text-center mb-4 anchor" id="policy">
          Policy &amp; compliance
        </h2>
        <p className="text-center mb-5 pt-2 d-none d-md-block">
          Deliver enhanced organisational command and control with <br />
          The Transformd Platform by aligning the customer experience to
          <br />
          existing business policies and procedures.
        </p>
        <p className="text-center mb-5 pt-2 d-block d-md-none">
          Deliver enhanced organisational command and control with The
          Transformd Platform by aligning the customer experience to existing
          business policies and procedures.
        </p>

        <p className="text-center mt-3">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/Policy-and-Compliance.png"
            alt="Policy &amp; compliance"
            className="mt-4 mb-4 benefit-img-hero oops d-none"
          />
        </p>
        <Row>
          <Col className="mb-md-5 mb-4" md={{ span: 5, offset: 1 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/data-accuracy.png"
                alt="Data accuracy"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Data Accuracy </h4>
            <p className="text-center">
              All data fields can be checked for format accuracy and cross
              checked against databases to improve the overall validity of
              information customers are entering. By adjusting the flow based on
              contextual information supplied by the customer, they only respond
              to necessary questions and information requests limiting the
              amount of information collected.
            </p>
          </Col>
          <Col className="mb-md-5 mb-4" md="5">
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/historical-tracking-and-version-control.png"
                alt="Historical Tracking &amp; Version Control"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">
              Historical Tracking &amp; Version Control
            </h4>
            <p className="text-center">
              The Transformd Platform’s version control and tracking
              capabilities allow you to catch and correct errors or
              non-compliance issues before they become major customer problems.
            </p>
          </Col>
          <Col className="mb-md-2 mt-md-5" md={{ offset: 1, span: 5 }}>
            <p className="text-center">
              <img
                src="https://s3-ap-southeast-2.amazonaws.com/transformd-cdn/img/benefits/access.png"
                alt="Access"
                className="benefit-img"
              />
            </p>
            <h4 className="text-center">Access</h4>
            <p className="text-center">
              Formatic enables administrator management to all employees who can
              access the platform granting access rights around, viewing,
              editing, approving and publishing to fit with your organisations
              policy and procedures.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionPane>

    <SectionPane size="xlg" variant="primary">
      <TimeTransformd />
    </SectionPane>
  </MainLayout>
)

export default BenefitsPage
