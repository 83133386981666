import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainLayout from "../layout/main"
import SEO from "../components/seo"
import Title from "../components/page/Title"
import Solutions from "../components/solutions/Preview"
import SectionPane from "../components/page/SectionPane"

const SolutionsPage = () => (
  <MainLayout>
    <SEO title="Solutions" />
    <Title headline="Our Solutions" subTitle="Solutions" />
    <SectionPane size="sm" className="box-shadow pt-5 pb-4 pb-md-5">
      <Container>
        <Row>
          <Col md="12" className="text-center pb-md-5 mt-4">
            <Solutions />
          </Col>
        </Row>
      </Container>
    </SectionPane>
  </MainLayout>
)

export default SolutionsPage
